import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import { Wrapper } from './styled'

const Logo = ({ logo, width }) => (
  <Link to="/">
    <Wrapper src={logo} width={`${width}px`} />
  </Link>
)

Logo.propTypes = {
  logo: PropTypes.string,
  width: PropTypes.number,
}

export default Logo
