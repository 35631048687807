import styled from 'styled-components'
import { red, black, grey, lightGrey } from '../../styles/colors'
import media from '../../styles/media'

export const Section = styled.section`
  font-family: 'Gilroy';
  width: 100%;
`

export const Container = styled.div`
  min-height: 100%;
  margin: ${({ isShowreel }) => (isShowreel ? '0' : '100px 0 0')};

  ${({ isHome }) =>
    isHome &&
    `
    position: relative;
    top: -50px;
    margin-top: 0;
    margin-bottom: 70px;
  `}

  ${media.tablet`
    position: relative;
    ${({ isHome }) => isHome && `margin-bottom: -14px`};
  `}

  ${media.phone`
    margin-top: ${({ isShowreel }) => (isShowreel ? '0' : '36px')};
  `}
`

export const SlideContainer = styled.div`
  display: none;

  ${media.tablet`
    display: block;
    margin-left: -20px;
    position: absolute;
    left: 50%;
    right: 50%;
    width: 40px;
    bottom: 30px;
  `}

  ${media.phone`
    bottom: 13px;
  `}
`

export const QuoteContainer = styled.div`
  padding-left: calc((100vw - 718px - 216px) / 2);
  width: 100vw;

  ${media.tablet`
    width: 100%;
    padding-left: 0;
    padding: 0 36px;
  `}

  ${media.desktopXl`
    width: 1920px;
    padding-left: calc((1920px - 718px - 216px) / 2);
  `}
`

export const BorderBox = styled.div`
  border: 3px solid ${lightGrey};
  border-right: none;
  padding-left: 109px;
  padding-top: 96px;
  padding-bottom: calc(96px + 100px);

  ${({ isHome }) =>
    isHome &&
    `
    padding-top: 146px;
  `}

  ${media.tablet`
    padding: 40px 36px 202px 36px;
    padding-top: 40px;
    padding-left: 36px;
    padding-bottom: ${({ isMoreThanOneSlide }) =>
      isMoreThanOneSlide ? '270px' : '220px'};
    border: 3px solid ${lightGrey};
    width: 100%;
    text-align: center;
    ${({ isHome }) => isHome && `padding-top: 90px`};
  `}

  ${media.phone`
    padding-right: 20px;
    padding-left: 20px;
  `}
`

export const QuoteOuter = styled.div`
  min-height: 100%;

  ${media.tablet` 
    width: 100%;
  `}
`

export const QuoteInner = styled.div`
  width: 718px;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.tablet` 
    width: 98%;
    margin: 0 auto;
  `}
`

export const QuoteWrapper = styled.div`
  color: ${black};
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  padding: 20px 0;

  ${media.phone`
    font-size: 16px;
    line-height: 24px;
  `}

  & h1 {
    font-size: 48px;
    line-height: 54px;
    letter-spacing: -0.48px;

    ${media.phone`
      font-size: 30px;
      letter-spacing: -0.3px;
      line-height: 35px;
    `}
  }

  & h2 {
    font-size: 30px;
    line-height: 40px;

    ${media.phone`
      font-size: 20px;
      line-height: 30px;
    `}
  }

  & h3 {
    line-height: 28px;
    font-size: 24px;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    ${media.phone`
      font-size: 18px;
      line-height: 28px;
    `}
  }

  & h4 {
    line-height: 28px;
    font-size: 24px;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    ${media.phone`
      font-size: 18px;
      line-height: 28px;
    `}
  }

  & strong {
    color: ${red};
  }

  & em {
    font-style: italic;
  }
`

export const LabelWrapper = styled.div`
  position: relative;

  ${media.tablet`
    width: calc(200px + ((100vw - 200px) / 2));
  `}
`

export const PersonSliderWrapper = styled.div`
  border: 3px solid ${lightGrey};
  border-left: none;
  position: absolute;
  bottom: 50px;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding: 23px 30px 23px calc((100vw - 718px - 380px) / 2);

  ${media.tablet`
    padding: 23px 0;
    width: 100%;
    justify-content: flex-end;
    bottom: ${({ isMoreThanOneSlide }) =>
      isMoreThanOneSlide ? '100px' : '50px'};
  `}

  ${media.desktopXl`
    padding-left: calc((1920px - 718px - 380px) / 2);
  `}
`

export const PersonWrapper = styled.div`
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-left: 150px;
  padding-left: 36px;

  ${media.tablet` 
    margin-left: 0;
    width: 200px;
    padding: 0 36px;
  `}

  ${({ isFadeIn }) =>
    isFadeIn &&
    `
    animation: fadeIn ease 0.5s;
    -webkit-animation: fadeIn ease 0.5s;
    -moz-animation: fadeIn ease 0.5s;
    -o-animation: fadeIn ease 0.5s;
    -ms-animation: fadeIn ease 0.5s;

    @keyframes fadeIn{
      0% {
        opacity:0;
      }
      100% {
        opacity:1;
      }
    }

    @-moz-keyframes fadeIn {
      0% {
        opacity:0;
      }
      100% {
        opacity:1;
      }
    }

    @-webkit-keyframes fadeIn {
      0% {
        opacity:0;
      }
      100% {
        opacity:1;
      }
    }

    @-o-keyframes fadeIn {
      0% {
        opacity:0;
      }
      100% {
        opacity:1;
      }
    }

    @-ms-keyframes fadeIn {
      0% {
        opacity:0;
      }
      100% {
        opacity:1;
      }
    }
  `};
`

export const SlideNav = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-content: center;
  justify-content: center;
  width: 41px;

  ${media.tablet`
    display: none;
  `}
`

export const SlideCounter = styled.div`
  display: flex;
  justify-content: center;
  color: ${grey};
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
`

export const SlideArrowsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`

export const SlideArrow = styled.div`
  cursor: pointer;
`

export const PersonName = styled.div`
  color: ${red};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;

  ${media.tablet`
    text-align: center;
  `}

  ${media.phone`
    font-size: 16px;
  `}
`

export const PersonJobTitle = styled.div`
  color: ${black};
  font-size: 12px;
  font-weight: 600;

  ${media.tablet`
    text-align: center;
  `}

  ${media.phone`
    font-size: 14px;
  `}
`

export const OpeningMark = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;

  ${media.tablet`
    top: 0px;
    left: 0px;
  `}
`

export const ClosingMark = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;

  ${media.tablet`
    bottom: 0;
    right: 0;
  `}
`

export const Mark = styled.img`
  width: 23px;
  height: 22px;
`
