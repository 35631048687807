import React from 'react'
import PropTypes from 'prop-types'
import VideoPlayer from './VideoPlayer'
import {
  PreviewContainer,
  GridItemWrapper,
  GridItemHeader,
  FullWidthHeaderWrapper,
  FullWidthHeader,
  ArrowDown,
} from './styled'
import ArrowDownRed from '../../assets/icons/arrow_down_red.png'

const VideoPreview = ({
  videoUrl,
  thumbnail,
  header,
  isFullWidth,
  isGridItem,
}) => {
  const renderHeader = () => {
    if (header && isGridItem) {
      return <GridItemHeader>{header}</GridItemHeader>
    }

    if (header && isFullWidth) {
      return (
        <FullWidthHeaderWrapper>
          <FullWidthHeader>{header}</FullWidthHeader>
          <ArrowDown src={ArrowDownRed} />
        </FullWidthHeaderWrapper>
      )
    }

    return null
  }

  const renderVideoPlayer = () => {
    if (isGridItem) {
      return (
        <GridItemWrapper>
          <VideoPlayer videoUrl={videoUrl} thumbnail={thumbnail} />
        </GridItemWrapper>
      )
    }

    return <VideoPlayer videoUrl={videoUrl} thumbnail={thumbnail} />
  }

  return (
    <PreviewContainer isGridItem={isGridItem}>
      {renderHeader()}
      {renderVideoPlayer()}
    </PreviewContainer>
  )
}

VideoPreview.propTypes = {
  videoUrl: PropTypes.string,
  thumbnail: PropTypes.string,
  header: PropTypes.string,
  isFullWidth: PropTypes.bool,
  isGridItem: PropTypes.bool,
}

export default VideoPreview
