import React from 'react'
import styled, { css } from 'styled-components'
import media from '../../styles/media'
import { red, black } from '../../styles/colors'
import PlayBtn from '../../assets/icons/play_button.png'

export const PreviewContainer = styled.div`
  ${({ isGridItem }) =>
    isGridItem &&
    css`
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      width: 50%;

      ${media.phone`
        width: 100%;
      `}
    `}
`

export const GridItemWrapper = styled.div`
  width: 92%;
  margin: 0 46px;

  ${media.tablet`
    margin: 0 24px;
  `}

  ${media.phone`
    width: 100%;
    height: 100%;
    margin: 0 auto;
  `}
`

export const GridItemHeader = styled.div`
  color: ${black};
  font-family: 'Gilroy';
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
  padding-left: 46px;
  padding-top: 62px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  /* max-width: 270px; */

  ${media.tablet`
    padding-left: 24px;
  `}

  ${media.phone`
    padding-left: 36px;
    font-size: 20px;
    margin-bottom: 16px;
  `}
`

export const FullWidthHeaderWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-bottom: 64px;

  ${media.phone`
    margin-bottom: 52px;
  `}
`

export const ArrowDown = styled.img`
  width: 10px;
  height: 14px;
`

export const FullWidthHeader = styled.div`
  color: ${black};
  font-family: 'Gilroy';
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;

  ${media.phone`
    font-size: 18px;
    margin-bottom: 14px;
  `}
`

export const Container = styled.div`
  max-width: 1920px;
  margin: 0 auto;

  & .player-wrapper {
    position: relative !important;
    padding-top: 64.323%;
    width: 100%;

    ${media.phone`
      padding-top: 56%;
    `}
  }

  & .react-player {
    position: absolute !important;
    top: 0;
    left: 0;
  }
`

export const EmptyDiv = styled.div`
  /* needed for occupaying the space underneath the player */
  height: 1px;
`

const PlayIconWrapper = styled.div`
  width: 84px;
  height: 84px;
  background-color: ${red};
  opacity: 0.78;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  & img {
    position: relative;
    left: 3px;
  }

  ${media.phone`
    width: 57px;
    height: 57px;

    & img {
      width: 20px;
    }
  `}
`

export const PlayIcon = () => (
  <PlayIconWrapper>
    <img src={PlayBtn} />
  </PlayIconWrapper>
)
