import React from 'react'
import PropTypes from 'prop-types'
import { Section, Wrapper, Title, Logo, LogoWrapper } from './styled'

const LogoGroup = ({ logos, isShowreel }) => {
  return (
    <Section isShowreel={isShowreel}>
      <Title>Clients we have worked with</Title>
      <Wrapper>
        {logos.map((item, i) => (
          <LogoWrapper key={i}>
            <Logo src={item.url} alt={item.alt && item.alt} />
          </LogoWrapper>
        ))}
      </Wrapper>
    </Section>
  )
}

LogoGroup.propTypes = {
  logos: PropTypes.array,
  isShowreel: PropTypes.bool,
}

export default LogoGroup
