import React from 'react'
import PropTypes from 'prop-types'

import { Container, IntroText, Wrapper, EmailAndPhone, Address } from './styled'

const Contact = ({ introText, emailAndPhone, address }) => (
  <Container>
    <IntroText dangerouslySetInnerHTML={{ __html: introText }} />
    <Wrapper>
      <EmailAndPhone dangerouslySetInnerHTML={{ __html: emailAndPhone }} />
      <Address dangerouslySetInnerHTML={{ __html: address }} />
    </Wrapper>
  </Container>
)

Contact.propTypes = {
  introText: PropTypes.string,
  emailAndPhone: PropTypes.string,
  address: PropTypes.string,
}

export default Contact
