import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import Button from '../Button'
import { linksArr } from '../../styles/links'
import { Container, Wrapper, Text, Line } from './styled'

const BreakerPreviewCard = ({ text, to, btnText, isBlogItem }) => {
  const match = link => link === to
  const isInternal = linksArr.some(match)

  return (
    <Container isBlogItem={isBlogItem}>
      <Wrapper isTo={to} isBlogItem={isBlogItem}>
        <Text isBlogItem={isBlogItem}>{text}</Text>
        <Line isTo={to} />
        {to && btnText && isInternal && (
          <Link to={to}>
            <Button>{btnText}</Button>
          </Link>
        )}
        {to && btnText && !isInternal && (
          <a href={to} target="_blank" rel="noopener noreferrer">
            <Button>{btnText}</Button>
          </a>
        )}
      </Wrapper>
    </Container>
  )
}

BreakerPreviewCard.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
  btnText: PropTypes.string,
  isBlogItem: PropTypes.bool,
}

export default BreakerPreviewCard
