import React from 'react'
import PropTypes from 'prop-types'
import { Section, Container } from './styled'

const Accordion = ({ children }) => {
  return (
    <Section>
      <Container>{children}</Container>
    </Section>
  )
}

Accordion.propTypes = {
  children: PropTypes.node,
}

export default Accordion
