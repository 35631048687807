import styled from 'styled-components'
import media from '../../styles/media'
import { black, white } from '../../styles/colors'

export const Wrapper = styled.div`
  position: relative;
`

export const Service = styled.span`
  cursor: pointer;
  border-bottom: 2px solid transparent;

  ${({ activeImage, id }) =>
    activeImage === id &&
    `
    border-bottom: 2px solid ${white};
  `};

  &:hover {
    border-bottom: 2px solid ${white};
  }

  transition: border-bottom 1s;

  ${media.phone`
    height: 35px;
    margin-bottom: 20px;
  `}
`

export const BarWrapper = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  position: relative;
  font-family: 'Gilroy';
  font-size: 20px;
  letter-spacing: -0.4px;
  line-height: 41px;
  font-weight: 600;
  text-align: center;
  background-color: ${black};
  color: ${white};
  margin-bottom: 40px;
  z-index: 1;
`

export const Content = styled.div`
  position: absolute;

  ${media.phone`
    height: auto;
    padding: 70px 0;
  `}
`

export const Bar = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  ${({ activeImage, id }) =>
    activeImage === id &&
    `
    opacity: 1;
  `};

  -webkit-transition: opacity 2s ease-in-out;
  -moz-transition: opacity 2s ease-in-out;
  -o-transition: opacity 2s ease-in-out;
  transition: opacity 2s ease-in-out;
`

export const Scrim = styled.div`
  position: absolute;
  background-color: ${black};
  opacity: 0.18;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`

export const Categories = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  & h3 {
    padding-bottom: 25px;
  }

  & h3:last-child {
    padding-bottom: 0;
  }
`

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
  z-index: 999;
  cursor: pointer;
  width: 195px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`
