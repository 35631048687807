import styled from 'styled-components'
import media from '../../styles/media'
import { white, red, black } from '../../styles/colors'

export const VimeoWrapper = styled.div`
  background-color: black;
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: ${({ videoRatio }) => `${(1 / videoRatio) * 100}%`};
`

export const Iframe = styled.iframe`
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;

  ${media.tablet`
    width: 100%;
  `}
`

export const OverlayText = styled.div`
  font-family: 'Gilroy';
  font-size: 48px;
  letter-spacing: -0.48px;
  line-height: 66px;
  font-weight: 300;
  color: ${white};
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
  padding: 50px;

  & strong {
    color: ${red};
    font-weight: 600;
  }

  & em {
    font-style: italic;
  }

  ${media.phone`
    font-size: 30px;
    letter-spacing: -0.3px;
    line-height: 35px;
    padding: 36px;
  `}
`

export const Scrim = styled.div`
  background-color: ${black};
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  ${media.phone`
    display: none;
  `}
`
