import styled from 'styled-components'
import { red, black, grey, white } from '../../styles/colors'
import media from '../../styles/media'

export const Section = styled.section`
  position: relative;
  width: 100%;
`

export const Wrapper = styled.div`
  font-family: 'Gilroy';
  width: 100%;
  height: 532px;
  background-color: ${grey};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 1;

  ${media.phone`
    margin-bottom: 30px;
    height: 400px;
  `}
`

export const Scrim = styled.div`
  background-color: ${black};
  opacity: 0.18;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`

export const ProjectProfile = styled.div`
  color: ${white};
  padding: 90px 0 0 140px;
  z-index: 3;

  ${media.tablet`
    padding: 90px 0 0 50px;
  `}

  ${media.phone`
    padding: 0;
    position: absolute;
    bottom: 40px;
    left: 30px;
    right: 0;
  `}
`

export const Heading = styled.h2`
  font-weight: 300;
  font-size: 48px;
  letter-spacing: -0.48px;
  line-height: 66px;
  margin-bottom: 30px;

  ${media.phone`
    font-size: 30px;
    margin: 0;
  `}
`

export const Label = styled.div`
  display: inline-flex;
  background-color: ${white};
  padding: 11px 15px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2.8px;
  color: black;

  ${media.phone`
    padding: 13px 13px 11px 13px;
    font-size: 12px;
    margin-right: 30px;
    line-height: 15px;
  `}
`

export const Tagline = styled.div`
  color: ${white};
  font-size: 24px;
  letter-spacing: -0.32px;
  line-height: 35px;
  font-weight: 600;
  margin: 15px 0 20px 0;
  width: 307px;

  ${media.phone`
    font-size: 20px;
    letter-spacing: -0.27px;
    line-height: 30px;
  `}
`

export const Controls = styled.div`
  z-index: 999;
  display: inline-flex;
  position: absolute;
  bottom: 106px;
  right: 48px;
  color: ${white};

  ${media.phone`
    display: none;
  `}
`

export const Control = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  background-color: ${red};
  margin-left: 9px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${white};
  }
`
