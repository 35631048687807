import styled from 'styled-components'
import { red, white } from '../../styles/colors'
import media from '../../styles/media'

export const Wrapper = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Gilroy';
  font-size: ${({ fSize }) => fSize || '12px'};
  padding: 0 13px 0 16px;
  height: 36px;
  width: ${({ width }) => width || '152px'};
  background-color: ${red};
  color: ${white};
  cursor: pointer;

  & > img {
    padding-left: 20px;
  }

  ${media.phone`
    font-size: 14px;
  `}
`
