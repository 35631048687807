import styled, { css } from 'styled-components'
import media from '../../../styles/media'
import { white, grey } from '../../../styles/colors'

export const Wrapper = styled.div`
  margin-bottom: 30px;
  width: 33.3%;
  display: flex;
  justify-content: flex-end;

  ${media.tablet`
    width: 300px;
    margin-bottom: 50px;
    justify-content: center;
  `}

  ${media.phone`
    max-width: calc(100vw - 72px);
  `}
`

export const WrapperInner = styled.div`
  width: 260px;

  ${media.tablet`
    width: 100%;
  `}
`

export const Card = styled.div`
  max-width: 260px;

  ${media.tablet`
    margin-right: 0;
    width: 100%;
    max-width: none;
  `}
`

const CardTextStyles = css`
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.27px;
`

export const Name = styled.div`
  ${CardTextStyles}
  line-height: 35px;
  ${({ isAnyCardOpen }) => isAnyCardOpen && 'opacity: 0.5'};
`

export const JobTitle = styled.div`
  ${CardTextStyles}
  line-height: 27px;
  color: ${grey};
  ${({ isAnyCardOpen }) => isAnyCardOpen && 'opacity: 0.5'};
`

export const Toggle = styled.img`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 8px 0 0;
  cursor: pointer;
`

export const Copy = styled.div`
  display: flex;
  margin-top: 30px;
`

export const ProfileImage = styled.div`
  position: relative;
  z-index: -2;
  height: 260px;
  width: 100%;

  background-color: ${grey};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${media.tablet`
    width: 100%;
    max-height: 260px;
  `}
`

export const Scrim = styled.div`
  background-color: ${white};
  opacity: 0.35;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  display: none;
  ${({ isAnyCardOpen, isCardOpen }) =>
    isAnyCardOpen && !isCardOpen && 'display: block'};
`
