import styled from 'styled-components'
import media from '../../../styles/media'
import { black, white, grey, darkGrey } from '../../../styles/colors'

export const LinkWrapper = styled.div`
  z-index: 500;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const Container = styled.div`
  position: relative;
  width: 27.7%;
  padding: 25.8% 48px 0;
  margin: 2.8%;

  ${media.desktop`
    width: 41.97%;
    padding: 38.936% 48px 0;
    margin: 4%;
  `}

  ${media.tablet`
    width: 45.344%;
    padding: 42.058% 15px 0;
    margin: 2.2%;
  `}

  ${media.phone`
    width: 100%;
    padding: 73.455% 18px 0;
    margin: 18px;
  `}
`

export const Wrapper = styled.div`
  font-family: 'Gilroy';
  background-color: ${grey};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  padding: 31px 35px;
  z-index: -10;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${media.tablet`
    padding: 17px 16px;
  `}
`

export const Scrim = styled.div`
  background-color: ${darkGrey};
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -9;
`

export const Tag = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2.8px;
  text-transform: uppercase;
  background-color: ${white};
  color: ${black};
  height: 36px;
  padding: 0 15px;
  margin-bottom: 30px;
  z-index: 100;

  ${media.tablet`
    font-size: 12px;
    letter-spacing: 2.4px;
    margin-bottom: 16px;
  `}
`

export const PostTitle = styled.div`
  color: ${white};
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.32px;
  line-height: 30px;
  margin-bottom: 24px;
  max-width: 65%;
  z-index: 100;

  ${media.tablet`
    width: 75%;
    min-width: 210px;
    max-width: 63%;
    font-size: 18px;
    letter-spacing: -0.24px;
    line-height: 24px;
    margin-bottom: 12px;
  `}
`

export const Tagline = styled.div`
  color: ${white};
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  max-width: 60%;
  z-index: 100;

  ${media.tablet`
    width: 75%;
    min-width: 210px;
    opacity: 0.8;
    font-weight: 600;
    letter-spacing: -0.24px;
    max-width: 88%;
  `}
`
