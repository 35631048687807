import React from 'react'
import PropTypes from 'prop-types'
import {
  HeaderContainer,
  AccItem,
  Toggler,
  TogglerWrapper,
  AccHeaderWrapper,
  AccHeader,
  AccSubheader,
  AccContent,
} from './styled'
import plusSymbol from '../../assets/icons/plus_symbol.png'
import minusSymbol from '../../assets/icons/minus_symbol.png'

class AccordionItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      maxHeight: '0',
      isOpen: false,
    }

    this.accContent = React.createRef()
    this.toggleItem = this.toggleItem.bind(this)
  }

  componentDidMount() {
    if (window.location.href.includes('#')) {
      const id = this.props.id
      const windowHashVal = location.hash.replace('#', '')

      if (id === windowHashVal) {
        this.setState({
          isOpen: true,
          maxHeight: `${this.accContent.current.scrollHeight}px`,
        })
      }
    }
  }

  toggleItem() {
    if (this.state.maxHeight === '0') {
      this.setState({
        maxHeight: `${this.accContent.current.scrollHeight}px`,
        isOpen: !this.state.isOpen,
      })
    } else {
      this.setState({
        maxHeight: '0',
        isOpen: !this.state.isOpen,
      })
    }
  }

  render() {
    const { title, subtitle, bodyText, ...restProps } = this.props
    return (
      <AccItem {...restProps} isOpen={this.state.isOpen}>
        <HeaderContainer onClick={this.toggleItem}>
          <TogglerWrapper>
            <Toggler
              isMinus
              src={minusSymbol}
              opacity={this.state.isOpen ? '1' : '0'}
              rotate={this.state.isOpen ? '180' : '90'}
            />
            <Toggler
              src={plusSymbol}
              opacity={!this.state.isOpen ? '1' : '0'}
              rotate={!this.state.isOpen ? '0' : '90'}
            />
          </TogglerWrapper>
          <AccHeaderWrapper>
            <AccHeader>{title}</AccHeader>
            <AccSubheader>{subtitle}</AccSubheader>
          </AccHeaderWrapper>
        </HeaderContainer>
        <AccContent
          ref={this.accContent}
          dangerouslySetInnerHTML={{ __html: bodyText }}
          maxHeight={this.state.maxHeight}
        />
      </AccItem>
    )
  }
}

AccordionItem.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  bodyText: PropTypes.string,
  id: PropTypes.string,
}

export default AccordionItem
