import styled from 'styled-components'
import media from '../../styles/media'

export const Wrapper = styled.div`
  font-family: 'Gilroy';
  position: relative;
  max-width: 720px;
  margin: 0 auto;
  margin-top: 100px;

  ${media.tablet`
    width: 100%;
  `}

  ${media.phone`
    padding: 0 36px;
    margin: 30px 0 0 0;
  `}

  p {
    margin: 30px 0;
    font-size: 18px;
    line-height: 32px;
    padding-left: 92px;

    ${media.phone`
      padding-left: 0;
      margin: 20px 0;
    `}
  }

  h1 {
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    margin: 40px 0;

    ${media.phone`
      font-size: 20px;
      line-height: 30px;
      margin: 20px 0;
    `}
  }

  img {
    width: auto;
    max-width: 60%;
    height: auto;
    float: right;
    margin: 10px 0 10px 10px;
  }
`
