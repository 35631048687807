import styled, { css } from 'styled-components'
import media from '../../styles/media'
import { white, red } from '../../styles/colors'
import { linkTypes, links } from '../../styles/links'

export const Wrapper = styled.div`
  font-family: 'Gilroy';
  font-size: ${({ fSize }) => fSize || '10px'};
  color: ${({ color }) => color && color};
  cursor: pointer;
  display: ${({ display }) => display || 'inline-flex'};

  ${({ linkType }) => {
    if (linkType === linkTypes.headerLink) {
      return css`
        font-family: 'Gilroy';
        font-weight: 600;
        font-size: 10px;
        line-height: 30px;
        color: ${({ color }) => color && color};
        color: ${({ location, to }) => location === to && red};
        color: ${({ location, label }) =>
          location && location.includes(links.work) && label === 'Work' && red};
        color: ${({ location, label }) =>
          location && location.includes(links.blog) && label === 'Blog' && red};
        border-bottom: ${({ location, to }) =>
          location === to && `1px solid ${red}`};
        border-bottom: ${({ location, label }) =>
          location &&
          location.includes(links.work) &&
          label === 'Work' &&
          `1px solid ${red}`};
        border-bottom: ${({ location, label }) =>
          location &&
          location.includes(links.blog) &&
          label === 'Blog' &&
          `1px solid ${red}`};
        cursor: pointer;
        display: inline-flex;
        margin-left: 25px;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        transition: all 0.2s;
        &:hover {
          color: ${red};
        }

        ${media.tablet`
          display: flex;
          margin: 0 0 50px 38px;
          font-size: 30px;
          letter-spacing: -0.3px
          text-transform: capitalize;
          color: ${white};
          color: ${({ location, to }) => location === to && red};
          color: ${({ location, label }) =>
            location &&
            location.includes(links.work) &&
            label === 'Work' &&
            red};
          color: ${({ location, label }) =>
            location &&
            location.includes(links.blog) &&
            label === 'Blog' &&
            red};
          border-bottom: none;
        `}
      `
    }
    if (linkType === linkTypes.footerLink) {
      return css`
        font-weight: 700;
        font-size: 10px;
        color: ${white};
        text-transform: uppercase;
        letter-spacing: 1.5px;
        display: flex;
        margin: 0 0 25px;

        ${media.tablet`
          display: flex;
          font-size: 12px;
          margin: 0 0 25px;
          letter-spacing: 1.8px;
        `}
      `
    }
    if (linkType === linkTypes.privacyLink) {
      return css`
        font-weight: 400;
        font-size: 12px;
        color: ${red};
        margin: 0 0 25px;
        text-decoration: underline;

        ${media.tablet`
          font-size: 14px;
        `}
      `
    }
  }}
`
