import styled from 'styled-components'
import media from '../../../styles/media'
import { grey, white } from '../../../styles/colors'

export const Wrapper = styled.div`
  font-family: 'Gilroy';
  cursor: pointer;
  width: 100%;
  padding: 0 48px 87px;
  max-width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '50%')};

  ${media.tablet`
    padding: 0 0 87px;
    max-width: 100%;
  `}

  ${media.phone`
    padding: 0 0 50px;
  `}
`

export const ImageBackground = styled.div`
  position: relative;
  height: 371px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: none;

  ${media.phone`
    ${({ isFullWidth }) => isFullWidth && 'display: block'};
  `}
`

export const ImageWrapper = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  background-color: white;

  ${media.phone`
    min-height: 350px;
  `}
`

export const Image = styled.img`
  width: 100%;
  height: auto;

  ${media.phone`
    ${({ isFullWidth }) => isFullWidth && 'display: none'};
  `}
`

export const LabelWrapper = styled.div`
  position: absolute;
  display: inline-flex;
  align-items: flex-end;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export const Label = styled.div`
  display: inline-flex;
  background-color: ${white};
  padding: 11px 15px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2.8px;
  margin-bottom: ${({ isFullWidth }) => (isFullWidth ? '30px' : '38px')};
  margin-left: ${({ isFullWidth }) => (isFullWidth ? '34px' : '43px')};

  ${media.phone`
    padding: 11px 13px; 
    font-size: 12px;
    bottom: 20px;
    left: 23px;
    line-height: 16px;
    margin: 20px;
  `}
`

export const TextContent = styled.p`
  font-size: 24px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: -0.3px;

  ${media.phone`
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.25px;
  `}
`

export const Tagline = styled(TextContent)`
  margin: 38px 0 10px 0;
  max-width: 540px;

  ${media.phone`
    margin: 30px 0 8px 0;
  `}
`

export const Tags = styled(TextContent)`
  color: ${grey};
  text-transform: capitalize;
`
