import React from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'

import ImageHero from 'components/ImageHero'
import Layout from 'components/Layout'
import Button from 'components/Button'
import Section from 'components/Section'
import InlineImageWithCaption from 'components/InlineImageWithCaption'
import SectionBlock from 'components/SectionBlock'
import QuoteWithImages from 'components/QuoteWithImages'
import StatsBreaker from 'components/StatsBreaker'
import LinksSummary from 'components/LinksSummary'
import LogoGroup from 'components/LogoGroup'
import FullWidthImage from 'components/FullWidthImage'
import MiniStatsBreaker from 'components/MiniStatsBreaker'
import TestimonialCarousel from 'components/Testimonial/CarouselParent'
import VideoPreview from 'components/VideoPreview'
import TwoColumnText from 'components/TwoColumnText'
import TextBlock from 'components/TextBlock'

import getTestimonialDetails from '../utils/testimonials/getTestimonialDetails'
import media from '../styles/media'
import { links } from '../styles/links'
import Arrow from '../assets/icons/arrow_left_black.svg'

const BtnWrapperTop = styled.div`
  max-width: 720px;
  margin: 58px auto 75px auto;

  ${media.phone`
    padding: 0 36px;
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 35px 0;
  `}
`

const BtnWrapperBottom = styled.div`
  max-width: 720px;
  margin: 50px auto 70px auto;
  padding-left: 97px;

  ${media.phone`
    padding: 0 36px;
    margin: 50px 0 30px 0;
  `}
`

const BlogButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Gilroy';
  line-height: 32px;
  font-size: 18px;
  cursor: pointer;
  border-bottom: 1px solid;

  & > img {
    padding-right: 10px;
  }
`

const BlogDetailPage = ({ data }) => {
  const {
    blog_categories,
    hero_image,
    subtitle,
    title,
    body_text,
    seo_metadata_example_to_include_in_any_type__meta_description,
    seo_metadata_example_to_include_in_any_type__meta_title,
    seo_metadata_example_to_include_in_any_type__opengraph_image,
  } = data.kenticoCloudItemBlogDetail.elements
  const { slug } = data.kenticoCloudItemBlogDetail.fields

  const buildTestimonialArr = testimonialGroup => {
    const allTestimonials = testimonialGroup
    let testimonialsArr = []
    allTestimonials.forEach(item => {
      const testimonial = getTestimonialDetails(item)
      if (testimonial) testimonialsArr.push(testimonial)
    })

    return testimonialsArr
  }

  return (
    <Layout
      location={`${links.blog}/${slug}`}
      metaDesc={
        seo_metadata_example_to_include_in_any_type__meta_description.value
      }
      metaTitle={seo_metadata_example_to_include_in_any_type__meta_title.value}
      metaImg={
        seo_metadata_example_to_include_in_any_type__opengraph_image.value[0]
          .url
      }
    >
      <ImageHero
        image={hero_image.value && hero_image.value[0].url}
        title={title.value}
        subtitle={subtitle.value}
        category={blog_categories.value && blog_categories.value[0].name}
      />
      <BtnWrapperTop>
        <Link to={links.blog}>
          <BlogButton width="auto">
            <img src={Arrow} />
            Back to all posts
          </BlogButton>
        </Link>
      </BtnWrapperTop>
      {body_text.linked_items.map(item => {
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemWidgetTextBlock'
        ) {
          return <TextBlock copy={item.elements.body_copy.value} />
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemSectionBlock'
        ) {
          return (
            <SectionBlock
              title={item.elements.section_title.value}
              heading={item.elements.heading.value}
              copy={item.elements.body_text.value}
            />
          )
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemQuoteWithImages'
        ) {
          return (
            <QuoteWithImages
              quoteCopy={item.elements.quote.value}
              leftImage={item.elements.left_image.value[0].url}
              leftImageCaption={item.elements.left_image_caption.value}
              rightImage={item.elements.right_image.value[0].url}
              rightImageCaption={item.elements.right_image_caption.value}
              leftImageDescription={
                item.elements.left_image.value[0].description
              }
              rightImageDescription={
                item.elements.right_image.value[0].description
              }
            />
          )
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemStatsBreaker'
        ) {
          return (
            <StatsBreaker
              title={item.elements.title.value}
              summary={item.elements.summary.value}
              statLabel1={item.elements.stat_label_1.value}
              statValue1={item.elements.stat_value_1.value}
              statLabel2={item.elements.stat_label_2.value}
              statValue2={item.elements.stat_value_2.value}
              statLabel3={item.elements.stat_label_3.value}
              statValue3={item.elements.stat_value_3.value}
            />
          )
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemLinkSummary'
        ) {
          return (
            <LinksSummary
              title={item.elements.title.value}
              category={item.elements.category.value}
              summaryText={item.elements.summary_text.value}
              linkName1={item.elements.link_name_1.value}
              linkUrl1={item.elements.link_url_1.value}
              linkName2={item.elements.link_name_2.value}
              linkUrl2={item.elements.link_url_2.value}
              linkName3={item.elements.link_name_3.value}
              linkUrl3={item.elements.link_url_3.value}
              linkName4={item.elements.link_name_4.value}
              linkUrl4={item.elements.link_url_4.value}
            />
          )
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemVideoPreview'
        ) {
          return (
            <Section mg="100px 0 0" mgMob="36px 0 0">
              <LazyLoad offset={100}>
                <VideoPreview
                  key={item.id}
                  isFullWidth
                  videoUrl={item.elements.video_url.value}
                  header={item.elements.title.value}
                  thumbnail={item.elements.preview_image.value[0].url}
                />
              </LazyLoad>
            </Section>
          )
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemTestimonialGroup'
        ) {
          return (
            <TestimonialCarousel
              isWorkDetail
              data={buildTestimonialArr(item.elements.testimonial_group)}
            />
          )
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemWidgetFullWidthImage'
        ) {
          return (
            <LazyLoad offset={100}>
              <FullWidthImage
                image={item.elements.image.value[0].url}
                imageName={item.elements.image.value[0].name}
              />
            </LazyLoad>
          )
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemFullWidthImageWithOverlayText'
        ) {
          return (
            <LazyLoad offset={100}>
              <FullWidthImage
                image={item.elements.image.value[0].url}
                overlayText={item.elements.overlay_text.value}
                imageName={item.elements.image.value[0].name}
              />
            </LazyLoad>
          )
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemInlineImageWithCaption'
        ) {
          return (
            <LazyLoad offset={100}>
              <InlineImageWithCaption
                image={
                  item.elements.image.value && item.elements.image.value[0].url
                }
                caption={item.elements.caption.value}
              />
            </LazyLoad>
          )
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemLogoGroup'
        ) {
          const allLogos = item.elements.logos
          let logosArr = []
          allLogos.forEach(item => {
            if (!item.elements) return

            const logo = {
              url: item.elements.image.assets[0].url,
              alt: item.elements.image.assets[0].description,
            }
            logosArr.push(logo)
          })
          return (
            <LazyLoad offset={100}>
              <LogoGroup logos={logosArr} />
            </LazyLoad>
          )
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemMiniStatsBreaker'
        ) {
          const stats = [
            {
              label: item.elements.label_1.value,
              value: item.elements.value_1.value,
            },
            {
              label: item.elements.label_2.value,
              value: item.elements.value_2.value,
            },
            {
              label: item.elements.label_3.value,
              value: item.elements.value_3.value,
            },
          ]
          return <MiniStatsBreaker stats={stats} />
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemTwoColumnText'
        ) {
          return (
            <TwoColumnText
              leftVal={item.elements.left_column.value}
              rightVal={item.elements.right_column.value}
            />
          )
        }
      })}
      <BtnWrapperBottom>
        <Link to={links.blog}>
          <Button width="auto">Read more blog posts</Button>
        </Link>
      </BtnWrapperBottom>
    </Layout>
  )
}

export default BlogDetailPage

BlogDetailPage.propTypes = {
  data: PropTypes.object,
}

export const query = graphql`
  query blogDetailQuery($slug: String!) {
    kenticoCloudItemBlogDetail(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      elements {
        seo_metadata_example_to_include_in_any_type__meta_description {
          value
        }
        seo_metadata_example_to_include_in_any_type__meta_title {
          value
        }
        seo_metadata_example_to_include_in_any_type__opengraph_image {
          value {
            url
          }
        }
        blog_categories {
          value {
            name
          }
        }
        hero_image {
          value {
            url
          }
        }
        subtitle {
          value
        }
        title {
          value
        }
        slug {
          value
        }
        body_text {
          linked_items {
            ... on KenticoCloudItemSectionBlock {
              id
              elements {
                body_text {
                  value
                }
                heading {
                  value
                }
                section_title {
                  value
                }
              }
              internal {
                type
              }
            }
            ... on KenticoCloudItemQuoteWithImages {
              id
              elements {
                left_image {
                  value {
                    url
                  }
                }
                left_image_caption {
                  value
                }
                quote {
                  value
                }
                right_image {
                  value {
                    url
                  }
                }
                right_image_caption {
                  value
                }
              }
              internal {
                type
              }
            }
            ... on KenticoCloudItemStatsBreaker {
              id
              elements {
                title {
                  value
                }
                summary {
                  value
                }
                stat_value_3 {
                  value
                }
                stat_value_2 {
                  value
                }
                stat_value_1 {
                  value
                }
                stat_label_3 {
                  value
                }
                stat_label_2 {
                  value
                }
                stat_label_1 {
                  value
                }
              }
              internal {
                type
              }
            }
            ... on KenticoCloudItemLinkSummary {
              id
              elements {
                title {
                  value
                }
                summary_text {
                  value
                }
                category {
                  value
                }
                link_name_1 {
                  value
                }
                link_name_2 {
                  value
                }
                link_name_3 {
                  value
                }
                link_name_4 {
                  value
                }
                link_url_1 {
                  value
                }
                link_url_2 {
                  value
                }
                link_url_3 {
                  value
                }
                link_url_4 {
                  value
                }
              }
              internal {
                type
              }
            }
            ... on KenticoCloudItemVideoPreview {
              id
              elements {
                preview_image {
                  value {
                    url
                  }
                }
                title {
                  value
                }
                video_url {
                  value
                }
                video_url {
                  value
                }
              }
              internal {
                type
              }
            }
            ... on KenticoCloudItemFullWidthImageWithOverlayText {
              id
              elements {
                image {
                  value {
                    url
                    name
                  }
                }
                overlay_text {
                  value
                }
              }
              internal {
                type
              }
            }
            ... on KenticoCloudItemLogoGroup {
              id
              internal {
                type
              }
              elements {
                logos {
                  ... on KenticoCloudItemLogo {
                    elements {
                      image {
                        assets {
                          url
                          description
                        }
                      }
                    }
                    internal {
                      type
                    }
                    id
                  }
                }
              }
            }
            ... on KenticoCloudItemTestimonialGroup {
              elements {
                ... on KenticoCloudItemTestimonialGroupElements {
                  testimonial_group {
                    ... on KenticoCloudItemTestimonial {
                      elements {
                        job_title {
                          value
                        }
                        name {
                          value
                        }
                        quote {
                          value
                        }
                      }
                    }
                  }
                }
              }
              id
              internal {
                type
              }
            }
            ... on KenticoCloudItemWidgetFullWidthImage {
              id
              elements {
                image {
                  value {
                    url
                    name
                  }
                }
              }
              internal {
                type
              }
            }
            ... on KenticoCloudItemFullWidthImageWithOverlayText {
              id
              elements {
                image {
                  value {
                    url
                    name
                  }
                }
                overlay_text {
                  value
                }
              }
              internal {
                type
              }
            }
            ... on KenticoCloudItemTwoColumnText {
              id
              elements {
                left_column {
                  value
                }
                right_column {
                  value
                }
              }
              internal {
                type
              }
            }
            ... on KenticoCloudItemWidgetTextBlock {
              id
              internal {
                type
              }
              elements {
                body_copy {
                  value
                }
              }
            }
            ... on KenticoCloudItemInlineImageWithCaption {
              id
              elements {
                image {
                  value {
                    url
                  }
                }
                caption {
                  value
                }
              }
              internal {
                type
              }
            }
            ... on KenticoCloudItemMiniStatsBreaker {
              id
              elements {
                label_1 {
                  value
                }
                label_2 {
                  value
                }
                label_3 {
                  value
                }
                value_1 {
                  value
                }
                value_2 {
                  value
                }
                value_3 {
                  value
                }
              }
              internal {
                type
              }
            }
          }
        }
      }
    }
  }
`
