import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import PeopleCard from './Card/index'
import withCurrentBreakpoint, {
  BREAKPOINTS,
} from '../../hoc/withCurrentBreakpoint'
import openQuoteMark from '../../assets/icons/opening_quote_mark.png'
import closeQuoteMark from '../../assets/icons/closing_quote_mark.png'
import closeIcon from '../../assets/icons/close_icon_white.svg'

import {
  CardWrapper,
  DetailWrapper,
  DetailName,
  DetailJobTitle,
  Summary,
  Close,
  Header,
  Quote,
  Detail,
  OpenQuote,
  CloseQuote,
  Container,
} from './styled'

class PeopleGrid extends Component {
  state = {
    isCardOpen: false,
    peopleArr: [],
    openCardId: null,
  }

  componentDidMount() {
    const { people } = this.props
    this.setState({ peopleArr: people })
  }

  arrangePeopleArr = (currentBreakpoint = this.props.currentBreakpoint) => {
    const { people } = this.props
    const mobileDisplays = [BREAKPOINTS.phone, BREAKPOINTS.tablet]
    const isMobile = mobileDisplays.includes(currentBreakpoint)
    let peopleArr = []

    if (!isMobile) {
      while (people.length) {
        const threePeople = people.splice(0, 3)
        peopleArr.push([threePeople])
      }
    } else {
      peopleArr = people
    }

    this.setState({ peopleArr: peopleArr })

    if (
      this.props.currentBreakpoint !== undefined &&
      this.props.currentBreakpoint === currentBreakpoint &&
      !isMobile
    ) {
      location.reload()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.currentBreakpoint) {
      this.arrangePeopleArr(nextProps.currentBreakpoint)
    }
  }

  updateIsCardOpen = id => {
    this.setState({
      isCardOpen: !this.state.isCardOpen,
      openCardId: id,
    })
  }

  triggerToggle = () => {
    this.refs.card.closeAll() // eslint-disable-line
  }

  render() {
    const { peopleArr, isCardOpen, openCardId } = this.state
    const { currentBreakpoint } = this.props
    const mobileDisplays = [BREAKPOINTS.phone, BREAKPOINTS.tablet]
    const isMobile = mobileDisplays.includes(currentBreakpoint)

    return (
      <>
        {isMobile ? (
          <>
            {peopleArr.map((person, i) => {
              return (
                <>
                  <LazyLoad offset={100}>
                    <CardWrapper>
                      <PeopleCard
                        id={person.id}
                        name={person.name}
                        jobTitle={person.jobTitle}
                        image={person.image}
                        updateIsCardOpen={this.updateIsCardOpen}
                        isAnyCardOpen={isCardOpen}
                        ref="card" // eslint-disable-line
                      />
                    </CardWrapper>
                  </LazyLoad>
                  <DetailWrapper
                    key={i}
                    isAnyCardOpen={isCardOpen}
                    openCardId={openCardId}
                    cardId={person.id}
                  >
                    <Detail>
                      <Summary
                        dangerouslySetInnerHTML={{ __html: person.summary }}
                      />
                      <Quote>
                        <OpenQuote src={openQuoteMark} />
                        {person.quote}
                        <CloseQuote src={closeQuoteMark} />
                      </Quote>
                    </Detail>
                  </DetailWrapper>
                </>
              )
            })}
          </>
        ) : (
          <>
            {peopleArr.map((persons, i) => {
              return (
                <Container key={i}>
                  <LazyLoad offset={100}>
                    <CardWrapper>
                      {persons[0].map((item, i) => {
                        return (
                          <PeopleCard
                            key={i}
                            id={item.id}
                            name={item.name}
                            jobTitle={item.jobTitle}
                            image={item.image}
                            updateIsCardOpen={this.updateIsCardOpen}
                            isAnyCardOpen={isCardOpen}
                            ref="card" // eslint-disable-line
                          />
                        )
                      })}
                    </CardWrapper>
                  </LazyLoad>
                  {persons[0].map((item, i) => (
                    <DetailWrapper
                      key={i}
                      isAnyCardOpen={isCardOpen}
                      openCardId={openCardId}
                      cardId={item.id}
                    >
                      <Header>
                        <div>
                          <DetailName>{item.name}</DetailName>
                          <DetailJobTitle>{item.jobTitle}</DetailJobTitle>
                        </div>
                        <Close>
                          <img onClick={this.triggerToggle} src={closeIcon} />
                        </Close>
                      </Header>
                      <Detail>
                        <Summary
                          dangerouslySetInnerHTML={{ __html: item.summary }}
                        />
                        <Quote>
                          <OpenQuote src={openQuoteMark} />
                          {item.quote}
                          <CloseQuote src={closeQuoteMark} />
                        </Quote>
                      </Detail>
                    </DetailWrapper>
                  ))}
                </Container>
              )
            })}
          </>
        )}
      </>
    )
  }
}

PeopleGrid.propTypes = {
  people: PropTypes.array,
  currentBreakpoint: PropTypes.string,
}

export default withCurrentBreakpoint(PeopleGrid)
