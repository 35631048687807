import React from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import {Link} from 'gatsby'
import {
  Wrapper,
  Image,
  ImageWrapper,
  ImageBackground,
  Label,
  LabelWrapper,
  Tagline,
  Tags,
} from './styled'

const DetailPreviewCard = ({
  to,
  isFullWidth,
  image,
  project,
  tagline,
  tags,
}) => {
  return (
    <LazyLoad>
      <Wrapper isFullWidth={isFullWidth}>
        <Link to={to}>
          <ImageWrapper>
            <ImageBackground
              isFullWidth={isFullWidth}
              style={{
                backgroundImage: `url("${image}")`,
              }}
            />
            <Image isFullWidth={isFullWidth} src={image} />
            <LabelWrapper>
              <Label dangerouslySetInnerHTML={{ __html: project }} />
            </LabelWrapper>
          </ImageWrapper>
          <Tagline>{tagline}</Tagline>
          <Tags>{tags}</Tags>
        </Link>
      </Wrapper>
    </LazyLoad>
  )
}

DetailPreviewCard.propTypes = {
  to: PropTypes.string,
  image: PropTypes.string,
  project: PropTypes.string,
  tagline: PropTypes.string,
  tags: PropTypes.string,
  isFullWidth: PropTypes.bool,
}

export default DetailPreviewCard
