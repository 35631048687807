import styled, { css } from 'styled-components'
import media from '../../styles/media'
import { red, grey } from '../../styles/colors'

export const Container = styled.div`
  margin-left: 20%;
`

export const CardWrapper = styled.div`
  display: flex;

  ${media.tablet`
    width: 100%;
    margin-left: auto;
    justify-content: center;
  `}
`

export const DetailWrapper = styled.div`
  margin-left: calc(100% - 66.6% - 260px);
  display: none;
  ${({ openCardId, cardId }) => openCardId === cardId && 'display: block'};

  ${media.tablet`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  `}
`

const DetailTextStyles = css`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.32px;
`

export const DetailName = styled.div`
  ${DetailTextStyles}
  line-height: 35px;
`

export const DetailJobTitle = styled.div`
  ${DetailTextStyles}
  line-height: 27px;
  color: ${grey};
`

export const Summary = styled.div`
  width: 60%;
  margin-top: 30px;

  p {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
  }

  ${media.tablet`
    width: 100%;
    max-width: 500px;
    margin: 0 auto;

    p {
      line-height: 30px;
    }
  `}
`

export const Quote = styled.p`
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  width: 40%;
  padding: 55px;
  position: relative;

  ${media.tablet`
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    font-size: 20px;
    line-height: 30px;
    padding: 35px;
  `}
`

export const Close = styled.button`
  background-color: ${red};
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`

export const Detail = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 60px;

  ${media.tablet`
    flex-flow: column;
  `}
`

export const OpenQuote = styled.img`
  width: 18px;
  position: absolute;
  top: 30px;
  left: 30px;

  ${media.tablet`
    left: 0;
  `}
`

export const CloseQuote = styled.img`
  width: 18px;
  position: absolute;
  bottom: 40px;
  right: 55px;

  ${media.tablet`
    right: 10px;
  `}
`
