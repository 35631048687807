import styled from 'styled-components'
import { black, lightGrey, red } from '../../styles/colors'
import media from '../../styles/media'

export const Section = styled.section`
  font-family: 'Gilroy';
  width: 100%;
  display: flex;
  justify-content: flex-end;
  max-width: 1920px;
  margin: 140px auto 0;

  ${media.tablet`
    flex-flow: column;
    align-items: center;
  `}

  ${media.phone`
    justify-content: center;
    margin: 36px auto 0;
  `}
`

export const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  max-width: 1230px;
  margin-right: 0;

  ${media.phone`
    margin: 0 auto;
  `}
`

export const QuoteContainer = styled.div`
  ${media.tablet`
    padding: 0 20px;
  `}

  ${media.phone`
    padding: 0 40px;
  `}
`

export const QuotationMarkWrapper = styled.div`
  margin-bottom: 32px;
`

export const QuoteCopy = styled.div`
  color: ${black};
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  width: 260px;
  margin-right: 70px;

  ${media.tablet`
    margin: 0 auto 70px;
    font-size: 20px;
    line-height: 30px;
  `}

  ${media.phone`
    width: 100%;
  `}

  & h1 {
    font-size: 48px;
    line-height: 54px;
    letter-spacing: -0.48px;

    ${media.phone`
      font-size: 30px;
      letter-spacing: -0.3px;
      line-height: 35px;
    `}
  }

  & h2 {
    font-size: 30px;
    line-height: 40px;

    ${media.phone`
      font-size: 20px;
      line-height: 30px;
    `}
  }

  & h3 {
    line-height: 28px;
    font-size: 24px;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    ${media.phone`
      font-size: 18px;
      line-height: 28px;
    `}
  }

  & h4 {
    line-height: 28px;
    font-size: 24px;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    ${media.phone`
      font-size: 18px;
      line-height: 28px;
    `}
  }

  & strong {
    color: ${red};
  }

  & em {
    font-style: italic;
  }
`

export const ImgContainer = styled.div`
  margin-left: 28px;

  ${media.tablet`
    width: 100%;
    margin: 0 auto 130px;
  `}

  ${media.phone`
    margin: 0 auto 40px;
  `}
`

export const ImagesContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  & > div:last-child {
    margin-bottom: 0;
  }

  ${media.tablet`
    flex-flow: column;
  `}

  ${media.phone`
    justify-content: center;
    align-content: center;
    width: 100%;
  `}
`

export const Img = styled.img`
  height: 545px;
  width: 415px;
  object-fit: cover;

  ${media.tablet`
    width: 100%;
    max-height: 415px;
    max-width: 768px;
  `}

  ${media.phone`
    max-height: 250px;
  `}
`

export const Caption = styled.div`
  display: flex;
  justify-content: center;
  padding: 18px 8px 15px 36px;
  max-width: 367px;
  position: relative;
  top: -3px;
  min-height: 50px;
  border: 3px solid ${lightGrey};
  border-top: none;
  color: ${black};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2.1px;
  text-transform: uppercase;
  line-height: 20px;
  flex-direction: column;

  ${media.phone`
    max-width: 325px;
    min-height: 75px;
    width: 100%;
  `}

  & h1 {
    font-size: 48px;
    line-height: 54px;
    letter-spacing: -0.48px;

    ${media.phone`
      font-size: 30px;
      letter-spacing: -0.3px;
      line-height: 35px;
    `}
  }

  & h2 {
    font-size: 30px;
    line-height: 40px;

    ${media.phone`
      font-size: 20px;
      line-height: 30px;
    `}
  }

  & h3 {
    line-height: 28px;
    font-size: 24px;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    ${media.phone`
      font-size: 18px;
      line-height: 28px;
    `}
  }

  & h4 {
    line-height: 28px;
    font-size: 24px;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    ${media.phone`
      font-size: 18px;
      line-height: 28px;
    `}
  }

  & strong {
    color: ${red};
  }

  & em {
    font-style: italic;
  }
`
