import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Section, Container, Title, Heading, Copy } from './styled'

class SectionBlock extends Component {
  render() {
    const { title, heading, copy } = this.props

    return (
      <Section>
        <Container noHeading={heading.length === 0}>
          <Title>{title}</Title>
          <Heading>{heading}</Heading>
          <Copy dangerouslySetInnerHTML={{ __html: copy }} />
        </Container>
      </Section>
    )
  }
}

SectionBlock.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  copy: PropTypes.string,
}

export default SectionBlock
