import React from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import { Container, PlayIcon, EmptyDiv } from './styled'

const VideoPlayer = ({ videoUrl, thumbnail }) => (
  <>
    <Container>
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url={videoUrl}
          controls={true}
          light={thumbnail || true}
          playIcon={<PlayIcon />}
          playing
          volume={0.5}
          width="100%"
          height="100%"
        />
      </div>
    </Container>
    <EmptyDiv />
  </>
)

VideoPlayer.propTypes = {
  videoUrl: PropTypes.string,
  thumbnail: PropTypes.string,
}

export default VideoPlayer
