import React from 'react'
import PropTypes from 'prop-types'
import {
  OuterFooterContainer,
  FooterContainer,
  InnerFooterContainer,
  OuterGoToTopButtonWrapper,
  GoToTopButtonWrapper,
  ContentContainer,
  LeftColWrapper,
  RightColWrapper,
  LogoWrapper,
  Header,
  CTA,
  SocialIconsWrapper,
  NavItemsWrapper,
  ButtonWrapper,
  PrivacyItemsWrapper,
} from './styled'
import { white, red } from '../../styles/colors'
import { linkTypes } from '../../styles/links'
import Button from '../Button'
import GoToTopButton from '../GoToTopButton'
import Logo from '../Logo'
// import SocialLink from '../SocialLink'
import GatsbyLink from '../GatsbyLink'

class Footer extends React.Component {
  handleScrollToTop = () => {
    this.props.handleScrollToTop()
  }

  render() {
    const {
      header,
      cta,
      logo,
      // socialLinks,
      navItems,
      privacyItems,
    } = this.props

    return (
      <OuterFooterContainer>
        <OuterGoToTopButtonWrapper>
          <GoToTopButtonWrapper>
            <GoToTopButton handleScrollToTop={this.handleScrollToTop} />
          </GoToTopButtonWrapper>
        </OuterGoToTopButtonWrapper>
        <FooterContainer>
          <InnerFooterContainer>
            <LogoWrapper>
              <Logo logo={logo} />
            </LogoWrapper>
            <ContentContainer>
              <LeftColWrapper>
                <Header>{header}</Header>
                <CTA>{cta}</CTA>
                <ButtonWrapper>
                  <a href="mailto:hello@blackpaintagency.com">
                    <Button>Get in touch</Button>
                  </a>
                </ButtonWrapper>
                <SocialIconsWrapper>
                  {/* {socialLinks.map(item => (
                    <SocialLink
                      to={item.url}
                      logo={item.logo}
                      key={item.name}
                    />
                  ))} */}
                </SocialIconsWrapper>
              </LeftColWrapper>
              <RightColWrapper>
                <NavItemsWrapper>
                  {navItems.map(item => (
                    <GatsbyLink
                      linkType={linkTypes.footerLink}
                      to={item.url}
                      label={item.label}
                      color={white}
                      key={item.label}
                    />
                  ))}
                </NavItemsWrapper>
                <PrivacyItemsWrapper>
                  <GatsbyLink
                    linkType={linkTypes.privacyLink}
                    key={privacyItems[0].label}
                    to={privacyItems[0].url}
                    label={privacyItems[0].label}
                    color={red}
                  />
                  {' | '}
                  <GatsbyLink
                    linkType={linkTypes.privacyLink}
                    key={privacyItems[1].label}
                    to={privacyItems[1].url}
                    label={privacyItems[1].label}
                    color={red}
                  />
                </PrivacyItemsWrapper>
              </RightColWrapper>
            </ContentContainer>
          </InnerFooterContainer>
        </FooterContainer>
      </OuterFooterContainer>
    )
  }
}

Footer.propTypes = {
  currentBreakpoint: PropTypes.string,
  header: PropTypes.string,
  cta: PropTypes.string,
  logo: PropTypes.string,
  socialLinks: PropTypes.array,
  navItems: PropTypes.array,
  privacyItems: PropTypes.array,
  handleScrollToTop: PropTypes.func,
}

export default Footer
