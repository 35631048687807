import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Section, TextWrapper, Text } from './styled'

class BodyText extends Component {
  render() {
    const { text, isAboutPage, isHomePage, isGeneralArticle } = this.props
    return (
      <Section>
        <TextWrapper
          isHomePage={isHomePage}
          isGeneralArticle={isGeneralArticle}
        >
          <Text
            dangerouslySetInnerHTML={{ __html: text }}
            isAboutPage={isAboutPage}
            isHomePage={isHomePage}
            isGeneralArticle={isGeneralArticle}
          />
        </TextWrapper>
      </Section>
    )
  }
}

BodyText.propTypes = {
  text: PropTypes.string,
  isAboutPage: PropTypes.bool,
  isHomePage: PropTypes.bool,
  isGeneralArticle: PropTypes.bool,
}

export default BodyText
