import styled, { css } from 'styled-components'
import media from '../../styles/media'
import { black, grey, white, darkGrey } from '../../styles/colors'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Gilroy';
  font-weight: 600;
  width: 100%;
  min-height: 495px;
  padding: 50px;
  margin-top: 107px;
  background-color: ${grey};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${media.tablet`
    max-width: 100%;
    padding: 36px;
    margin-top: 0;
  `}

  ${media.phone`
    min-height: 246px;
  `}

  ${({ isAbout }) =>
    isAbout &&
    css`
      margin-top: 0;
      background-color: ${black};
      min-height: 396px;

      ${media.tablet`
      min-height: 283px;
    `}
    `}
`

export const Category = styled.div`
  display: inline-flex;
  background-color: ${white};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2.8px;
  padding: 11px 15px;
  margin-bottom: 43px;
  z-index: 10;

  ${media.phone`
    padding: 11px 13px; 
    font-size: 12px;
    margin-bottom: 11px;
  `}
`

export const Title = styled.h1`
  font-size: 48px;
  letter-spacing: -0.48px;
  color: ${white};
  margin-bottom: 26px;
  text-align: center;
  z-index: 10;

  ${media.phone`
    font-size: 30px;
    letter-spacing: -0.3px;
    line-height: 54px;
    margin-bottom: 10px;
  `}

  ${({ isAbout }) =>
    isAbout &&
    css`
      letter-spacing: -0.48px;
      font-weight: 300;
    `}
`

export const Subtitle = styled.h2`
  font-size: 24px;
  letter-spacing: -0.32px;
  line-height: 35px;
  color: ${white};
  text-align: center;
  z-index: 10;

  ${media.phone`
    line-height: 28px;
    letter-spacing: -0.27px;
    font-size: 20px;
  `}
`

export const Scrim = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${darkGrey};
  opacity: 0.4;

  ${({ isAbout }) =>
    isAbout &&
    css`
      display: none;
    `}
`
