import React from 'react'
import PropTypes from 'prop-types'
import {
  Wrapper,
  Categories,
  Scrim,
  ButtonWrapper,
  Bar,
  Service,
  BarWrapper,
  Content,
} from './styled'
import Button from 'components/Button'
import { Link }from 'gatsby'
import { links } from '../../styles/links'
import withCurrentBreakpoint, {
  BREAKPOINTS,
} from '../../hoc/withCurrentBreakpoint'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

class ServicesCarousel extends React.Component {
  state = {
    activeImage: null,
    counter: 0,
    isLinkActive: false,
  }

  componentDidMount() {
    const { services } = this.props
    this.setState({
      activeImage: services[0].imageUrl,
    })
    this.timeout = setTimeout(this.changeBackground, 4 * 1000)
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout)
  }

  showBackground = url => {
    this.setState(
      {
        activeImage: url,
        isLinkActive: true,
      },
      () => {
        if (this.timeout) clearTimeout(this.timeout)
      }
    )
  }

  hideBackground = () => {
    this.setState(
      {
        isLinkActive: false,
      },
      () => {
        this.timeout = setTimeout(this.changeBackground, 4 * 1000)
      }
    )
  }

  changeBackground = () => {
    const { services } = this.props
    const { isLinkActive } = this.state

    if (!isLinkActive) {
      this.setState(
        function({ counter }) {
          const nextBackgroundIndex = ++counter % this.props.services.length
          return {
            counter: nextBackgroundIndex,
            activeImage: services[nextBackgroundIndex].imageUrl,
          }
        },
        function() {
          this.timeout = setTimeout(this.changeBackground, 4 * 1000)
        }
      )
    }
  }

  render() {
    const { services, currentBreakpoint } = this.props
    const mobileDisplays = [BREAKPOINTS.phone, BREAKPOINTS.tablet]
    const isMobile = mobileDisplays.includes(currentBreakpoint)
    const { activeImage } = this.state

    return (
      <Wrapper>
        <BarWrapper>
          <Bar
            activeImage={activeImage}
            id={services[2].imageUrl}
            src={services[2].imageUrl}
          />
          <Bar
            activeImage={activeImage}
            id={services[1].imageUrl}
            src={services[1].imageUrl}
          />
          <Bar
            activeImage={activeImage}
            id={services[0].imageUrl}
            src={services[0].imageUrl}
          />
          <Scrim />
          <Content>
            {isMobile ? (
              <Categories>
                <AnchorLink to={`${links.about}#${services[0].anchor}`}>
                  <Service
                    to={`${links.about}#${services[0].anchor}`}
                    activeImage={activeImage}
                    id={services[0].imageUrl}
                    onMouseEnter={() =>
                      this.showBackground(services[0].imageUrl)
                    }
                    onMouseLeave={this.hideBackground}
                  >
                    {services[0].title}
                  </Service>
                </AnchorLink>
                <AnchorLink to={`${links.about}#${services[1].anchor}`}>
                  <Service
                    to={`${links.about}#${services[1].anchor}`}
                    activeImage={activeImage}
                    id={services[1].imageUrl}
                    onMouseEnter={() =>
                      this.showBackground(services[1].imageUrl)
                    }
                    onMouseLeave={this.hideBackground}
                  >
                    {services[1].title}
                  </Service>
                </AnchorLink>
                <AnchorLink to={`${links.about}#${services[2].anchor}`}>
                  <Service
                    to={`${links.about}#${services[2].anchor}`}
                    activeImage={activeImage}
                    id={services[2].imageUrl}
                    onMouseEnter={() =>
                      this.showBackground(services[2].imageUrl)
                    }
                    onMouseLeave={this.hideBackground}
                  >
                    {services[2].title}
                  </Service>
                </AnchorLink>
              </Categories>
            ) : (
              <h3>
                <AnchorLink to={`${links.about}#${services[0].anchor}`}>
                  <Service
                    activeImage={activeImage}
                    id={services[0].imageUrl}
                    onMouseEnter={() =>
                      this.showBackground(services[0].imageUrl)
                    }
                    onMouseLeave={this.hideBackground}
                  >
                    {services[0].title}
                  </Service>
                </AnchorLink>
                ,&nbsp;
                <AnchorLink to={`${links.about}#${services[1].anchor}`}>
                  <Service
                    activeImage={activeImage}
                    id={services[1].imageUrl}
                    onMouseEnter={() =>
                      this.showBackground(services[1].imageUrl)
                    }
                    onMouseLeave={this.hideBackground}
                  >
                    {services[1].title}
                  </Service>
                </AnchorLink>
                ,&nbsp;
                <AnchorLink to={`${links.about}#${services[2].anchor}`}>
                  <Service
                    activeImage={activeImage}
                    id={services[2].imageUrl}
                    onMouseEnter={() =>
                      this.showBackground(services[2].imageUrl)
                    }
                    onMouseLeave={this.hideBackground}
                  >
                    {services[2].title}
                  </Service>
                </AnchorLink>
              </h3>
            )}
          </Content>
        </BarWrapper>
        <ButtonWrapper>
          <Link to={links.about}>
            <Button width="195px">About Black Paint</Button>
          </Link>
        </ButtonWrapper>
      </Wrapper>
    )
  }
}

ServicesCarousel.propTypes = {
  services: PropTypes.array,
  currentBreakpoint: PropTypes.string,
}

export default withCurrentBreakpoint(ServicesCarousel)
