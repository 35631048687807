import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import Title from 'components/Title'
import Contact from 'components/Contact'

import media from '../styles/media'
import { links } from '../styles/links'

const StyledTitle = styled(Title)`
  padding: 100px 48px 32px;

  ${media.tablet`
    padding: 45px 24px 40px;
  `}

  ${media.phone`
    padding: 45px 18px 40px;
  `}
`

const TramLines = styled.div`
  width: 100%;
  padding: 0 91px;

  ${media.tablet`
    padding: 0 62px;
  `}

  ${media.phone`
    padding: 0 18px;
  `}
`

const ContactPage = ({ data }) => {
  const {
    title,
    intro_text,
    email_and_phone,
    address,
    seo_metadata_example_to_include_in_any_type__meta_description,
    seo_metadata_example_to_include_in_any_type__meta_title,
    seo_metadata_example_to_include_in_any_type__opengraph_image,
  } = data.kenticoCloudItemContactPage.elements

  return (
    <Layout
      location={links.contact}
      metaDesc={
        seo_metadata_example_to_include_in_any_type__meta_description.value
      }
      metaTitle={seo_metadata_example_to_include_in_any_type__meta_title.value}
      metaImg={
        seo_metadata_example_to_include_in_any_type__opengraph_image.value[0]
          .url
      }
    >
      <TramLines>
        <StyledTitle>{title.value}</StyledTitle>
        <Contact
          introText={intro_text.resolvedHtml}
          emailAndPhone={email_and_phone.value}
          address={address.value}
        />
      </TramLines>
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.object,
}

export default ContactPage

export const query = graphql`
  {
    kenticoCloudItemContactPage {
      elements {
        seo_metadata_example_to_include_in_any_type__meta_description {
          value
        }
        seo_metadata_example_to_include_in_any_type__meta_title {
          value
        }
        seo_metadata_example_to_include_in_any_type__opengraph_image {
          value {
            url
          }
        }
        title {
          value
        }
        intro_text {
          resolvedHtml
        }
        email_and_phone {
          value
        }
        address {
          value
        }
      }
    }
  }
`
