import React from 'react'
import PropTypes from 'prop-types'
import {
  BlockContainer,
  MetaDataContainer,
  SummaryContainer,
  DatumContainer,
  DatumName,
  Datum,
  Summary,
  Separator,
} from './styled'

class SummaryBlock extends React.Component {
  render() {
    const { projectName, clientName, services, summary } = this.props
    return (
      <BlockContainer>
        <MetaDataContainer>
          <DatumContainer>
            <DatumName>Project:</DatumName>
            <Datum>{projectName}</Datum>
          </DatumContainer>
          {clientName && (
            <DatumContainer>
              <DatumName>Client:</DatumName>
              <Datum>{clientName}</Datum>
            </DatumContainer>
          )}
          {services && (
            <DatumContainer>
              <DatumName>Services:</DatumName>
              <Datum>{services}</Datum>
            </DatumContainer>
          )}
        </MetaDataContainer>
        <Separator />
        <SummaryContainer>
          <Summary dangerouslySetInnerHTML={{ __html: summary }} />
        </SummaryContainer>
      </BlockContainer>
    )
  }
}

SummaryBlock.propTypes = {
  projectName: PropTypes.string,
  clientName: PropTypes.string,
  services: PropTypes.string,
  summary: PropTypes.string,
}

export default SummaryBlock
