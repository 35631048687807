import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from './styled'

const SummaryGrid = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

SummaryGrid.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SummaryGrid
