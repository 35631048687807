import styled from 'styled-components'
import { red, black } from '../../styles/colors'
import media from '../../styles/media'

export const Section = styled.section`
  font-family: 'Gilroy';
  width: 100%;
  margin-top: 100px;

  ${media.phone`
    margin-top: 36px;
    padding: 0 36px;
  `}
`

export const Container = styled.div`
  display: flex;
  flex-flow: ${({ noHeading }) => (noHeading ? 'row' : 'column')};
  max-width: 900px;
  width: 55.719%;
  margin: 0 auto;

  ${media.tablet`
    width: 100%;
    max-width: 720px;
  `}

  ${media.phone`
    flex-flow: column;
  `}
`

export const Title = styled.h2`
  color: ${red};
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2.4px;
  line-height: 41px;
  margin-bottom: 16px;
`

export const Heading = styled.h3`
  color: ${black};
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 16px;

  ${media.phone`
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;
  `}
`

export const Copy = styled.div`
  color: ${black};
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  padding-left: 45%;

  ${media.phone`
    padding-left: 0;
    line-height: 30px;
  `}

  & h1 {
    font-size: 48px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.48px;

    ${media.phone`
      font-size: 30px;
      letter-spacing: -0.3px;
      line-height: 35px;
    `}
  }

  & h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;

    ${media.phone`
      font-size: 20px;
      line-height: 30px;
    `}
  }

  & h3 {
    line-height: 28px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    ${media.phone`
      font-size: 18px;
      line-height: 28px;
    `}
  }

  & h4 {
    line-height: 28px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    ${media.phone`
      font-size: 18px;
      line-height: 28px;
    `}
  }

  & strong {
    color: ${red};
    font-weight: 600;
  }

  & em {
    font-style: italic;
  }
`
