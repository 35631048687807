import styled from 'styled-components'
import media from '../../styles/media'
import { red, lightGrey } from '../../styles/colors'

export const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 0 100px 0;
  width: 100%;
  flex-flow: column;
  background-color: ${lightGrey};
  margin-bottom: ${({ isShowreel }) => isShowreel && '-50px'};
  margin-top: 100px;

  ${media.phone`
    padding: 50px 36px 70px 36px;
    margin-top: 36px;
  `}
`

export const Wrapper = styled.div`
  width: 697px;
  justify-content: space-between;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  ${media.phone`
    width: 100%;
  `}
`

export const Title = styled.h2`
  font-family: 'Gilroy';
  font-weight: 600;
  font-size: 20px;
  line-height: 41px;
  color: ${red};
  text-align: center;
  margin-bottom: 20px;

  ${media.phone`
    line-height: 31px;
  `}
`

export const LogoWrapper = styled.div`
  display: flex;
  flex-basis: 20%;
  justify-content: center;
  align-items: center;
  padding: 10px;

  ${media.tablet`
    flex-basis: 25%;
  `}

  ${media.phone`
    flex-basis: 33%;
  `}
`

export const Logo = styled.img`
  max-width: 100px;
  width: 100%;
  display: flex-inline;
`
