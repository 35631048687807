import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Text, Line } from './styled'

const IntroText = ({ text }) => (
  <Wrapper>
    <Text>{text}</Text>
    <Line />
  </Wrapper>
)

IntroText.propTypes = {
  text: PropTypes.string,
}

export default IntroText
