import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import LazyLoad from 'react-lazyload'

import Layout from 'components/Layout'
import { links } from '../styles/links'
import BodyText from 'components/BodyText'
import FullWidthImage from 'components/FullWidthImage'
import TextAndImageBreaker from 'components/TextAndImageBreaker'
import Accordion from 'components/Accordion'
import AccordionItem from 'components/Accordion/AccordionItem'
import HeroImage from 'components/HeroImage'
import HeroVideo from 'components/HeroVideo'
import '../resolvers/TwoColumnText/style.css'

const AboutPage = ({ data }) => {
  const {
    text_overlay,
    image_hero,
    video_hero,
    hero_choice,
    body_text,
    full_width_image,
    accordion,
    grey_box_image,
    grey_box_subtitle,
    grey_box_summary,
    grey_box_title,
    seo_metadata_example_to_include_in_any_type__meta_description,
    seo_metadata_example_to_include_in_any_type__meta_title,
    seo_metadata_example_to_include_in_any_type__opengraph_image,
  } = data.kenticoCloudItemAboutPage.elements

  const isHeroImage = hero_choice.value[0].codename.includes('image')
  const url = image_hero.value[0] ? image_hero.value[0].url : null
  return (
    <Layout
      location={links.about}
      metaDesc={
        seo_metadata_example_to_include_in_any_type__meta_description.value
      }
      metaTitle={seo_metadata_example_to_include_in_any_type__meta_title.value}
      metaImg={
        seo_metadata_example_to_include_in_any_type__opengraph_image.value[0]
          .url
      }
      isHeroImage={isHeroImage}
    >
      {isHeroImage ? (
        <HeroImage text={text_overlay.value} imageUrl={url} />
      ) : (
        <HeroVideo text={text_overlay.value} videoId={video_hero.value} />
      )}

      <BodyText isAboutPage text={body_text.resolvedHtml} />

      <LazyLoad offset={100}>
        <FullWidthImage image={full_width_image.value[0].url} />
      </LazyLoad>

      <TextAndImageBreaker
        title={grey_box_title.value}
        subtitle={grey_box_subtitle.value}
        summary={grey_box_summary.value}
        imageUrl={grey_box_image.value[0].url}
      />
      <Accordion>
        {accordion.map((accItem, i) => (
          <AccordionItem
            key={i}
            id={accItem.elements.anchor.value}
            title={accItem.elements.title.value}
            subtitle={accItem.elements.subtitle.value}
            bodyText={accItem.elements.body_text.resolvedHtml}
          />
        ))}
      </Accordion>
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object,
}

export default AboutPage

export const query = graphql`
  {
    kenticoCloudItemAboutPage {
      elements {
        seo_metadata_example_to_include_in_any_type__meta_description {
          value
        }
        seo_metadata_example_to_include_in_any_type__meta_title {
          value
        }
        seo_metadata_example_to_include_in_any_type__opengraph_image {
          value {
            url
          }
        }
        text_overlay {
          value
        }
        image_hero {
          value {
            url
          }
        }
        video_hero {
          value
        }
        hero_choice {
          value {
            codename
          }
        }
        body_text {
          value
          resolvedHtml
          type
        }
        full_width_image {
          value {
            url
            name
          }
        }
        accordion {
          elements {
            title {
              value
            }
            subtitle {
              value
            }
            anchor {
              value
            }
            body_text {
              value
              resolvedHtml
              linked_items {
                elements {
                  left_column {
                    value
                    resolvedHtml
                  }
                  right_column {
                    value
                    resolvedHtml
                  }
                }
              }
            }
          }
          id
          internal {
            type
          }
        }
        grey_box_image {
          value {
            url
            name
          }
        }
        grey_box_subtitle {
          value
        }
        grey_box_summary {
          value
        }
        grey_box_title {
          value
        }
      }
      internal {
        type
      }
    }
  }
`
