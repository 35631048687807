import React, { Component } from 'react'
import PropTypes from 'prop-types'
import closeAccordionIcon from '../../../assets/icons/close_accordion_icon.svg'
import openAccordionIcon from '../../../assets/icons/open_accordion_icon.svg'
import openAccordionIconDisabled from '../../../assets/icons/open_accordion_icon_disabled.svg'

import {
  Wrapper,
  Name,
  JobTitle,
  Toggle,
  Copy,
  Card,
  Scrim,
  ProfileImage,
  WrapperInner,
} from './styled'

class PeopleCard extends Component {
  state = {
    isCardOpen: false,
  }

  toggle = () => {
    const { isCardOpen } = this.state
    const { id, updateIsCardOpen } = this.props
    this.setState({ isCardOpen: !isCardOpen }, () => {
      if (isCardOpen) {
        updateIsCardOpen(null)
      } else {
        updateIsCardOpen(id)
      }
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isAnyCardOpen === false) {
      this.setState({
        isCardOpen: false,
      })
    }
  }

  closeAll = () => {
    const { updateIsCardOpen } = this.props
    updateIsCardOpen(null)
  }

  render() {
    const { name, jobTitle, image, isAnyCardOpen } = this.props
    const { isCardOpen } = this.state

    return (
      <Wrapper>
        <WrapperInner>
          <Card>
            <ProfileImage style={{ backgroundImage: `url("${image}")` }}>
              <Scrim isAnyCardOpen={isAnyCardOpen} isCardOpen={isCardOpen} />
            </ProfileImage>
            <Copy>
              <Toggle
                src={
                  isCardOpen
                    ? closeAccordionIcon
                    : isAnyCardOpen
                    ? openAccordionIconDisabled
                    : openAccordionIcon
                }
                onClick={isAnyCardOpen && !isCardOpen ? null : this.toggle}
              />
              <div>
                <Name isAnyCardOpen={isAnyCardOpen}>{name}</Name>
                <JobTitle isAnyCardOpen={isAnyCardOpen}>{jobTitle}</JobTitle>
              </div>
            </Copy>
          </Card>
        </WrapperInner>
      </Wrapper>
    )
  }
}

PeopleCard.propTypes = {
  name: PropTypes.string,
  jobTitle: PropTypes.string,
  isAnyCardOpen: PropTypes.bool,
  updateIsCardOpen: PropTypes.func,
  id: PropTypes.string,
  image: PropTypes.string,
}

export default PeopleCard
