import styled from 'styled-components'
import media from '../../../styles/media'

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 68px;
  justify-content: space-between;

  ${media.tablet`
    margin-bottom: 20px;
  `}

  ${media.phone`
    justify-content: center;
  `}
`
