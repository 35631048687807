import styled, { css } from 'styled-components'
import { red, black, lightGrey } from '../../styles/colors'
import media from '../../styles/media'

export const Section = styled.section`
  display: flex;
  justify-content: center;
  padding: 90px 0 140px;
  font-family: 'Gilroy';
  text-align: center;
  background-color: ${lightGrey};
  margin-top: 120px;

  ${media.phone`
    margin-top: 36px;
  `}
`

export const Container = styled.div`
  width: 90%;
  max-width: 720px;

  ${media.phone`
    width: 80%;
  `}
`

export const Title = styled.h2`
  color: ${red};
  font-size: 48px;
  font-weight: 600;
  letter-spacing: -0.48px;
  line-height: 54px;

  ${media.phone`
    font-size: 30px;
    letter-spacing: -0.3px;
    line-height: 35px;
  `}
`

export const Category = styled.h3`
  color: ${red};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2.4px;
  line-height: 41px;
  text-transform: uppercase;
  margin-bottom: 60px;
`

export const SummaryText = styled.div`
  color: ${black};
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  max-width: 395px;
  margin: 0 auto;

  ${media.phone`
    max-width: 302px;
    line-height: 30px;
  `}
`

export const LinksWrapper = styled.div`
  margin-top: 80px;
`

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  height: 73px;
  border-bottom: 1px solid ${black};
  margin-bottom: 8px;
  cursor: pointer;
`

const LinkStyles = css`
  display: flex;
  align-content: center;
  align-items: center;
  color: ${black};
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.32px;

  ${media.phone`
    font-size: 18px;
    letter-spacing: -0.24px;
  `}
`

export const StyledLinkExt = styled.a`
  ${LinkStyles}
`

export const Separator = styled.div`
  margin: 60px auto;
  width: 42px;
  height: 1px;
  background-color: ${red};

  ${media.phone`
    margin: 40px auto;
  `}
`
