import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Image, Caption, Line, InnerWrapper } from './styled'

const InlineImageWithCaption = ({ image, caption }) => (
  <Wrapper>
    <InnerWrapper>
      <Image src={image} />
      <Line />
      <Caption>{caption}</Caption>
    </InnerWrapper>
  </Wrapper>
)

InlineImageWithCaption.propTypes = {
  image: PropTypes.string,
  caption: PropTypes.string,
}

export default InlineImageWithCaption
