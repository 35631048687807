import React from 'react'
import PropTypes from 'prop-types'
import { Section, Container, StatWrapper, StatNum, StatLabel } from './styled'

const MiniStatsBreaker = ({ stats }) => {
  return (
    <Section>
      <Container>
        {stats.map((item, i) => {
          if (item.value === '' || item.label === '') {
            return null
          }
          return (
            <StatWrapper key={i}>
              <StatNum>{item.value}</StatNum>
              <StatLabel>{item.label}</StatLabel>
            </StatWrapper>
          )
        })}
      </Container>
    </Section>
  )
}

MiniStatsBreaker.defaultProps = {
  stats: [],
}

MiniStatsBreaker.propTypes = {
  stats: PropTypes.array,
}

export default MiniStatsBreaker
