import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import {
  Section,
  Container,
  QuoteContainer,
  QuotationMarkWrapper,
  QuoteCopy,
  ImagesContainer,
  ImgContainer,
  Img,
  Caption,
} from './styled'
import quoteMark from '../../assets/icons/opening_quote_mark.png'

class QuoteWithImages extends Component {
  render() {
    const {
      quoteCopy,
      leftImage,
      rightImage,
      leftImageCaption,
      rightImageCaption,
      leftImageDescription,
      rightImageDescription,
    } = this.props

    return (
      <Section>
        <Container>
          <QuoteContainer>
            <QuotationMarkWrapper>
              <img src={quoteMark} alt={`"`} />
            </QuotationMarkWrapper>
            <div>
              <QuoteCopy dangerouslySetInnerHTML={{ __html: quoteCopy }} />
            </div>
          </QuoteContainer>
          <ImagesContainer>
            <ImgContainer>
              <LazyLoad offset={100}>
                <Img src={leftImage} alt={leftImageDescription} />
              </LazyLoad>
              <Caption dangerouslySetInnerHTML={{ __html: leftImageCaption }} />
            </ImgContainer>
            <ImgContainer>
              <LazyLoad offset={100}>
                <Img src={rightImage} alt={rightImageDescription} />
              </LazyLoad>
              <Caption
                dangerouslySetInnerHTML={{ __html: rightImageCaption }}
              />
            </ImgContainer>
          </ImagesContainer>
        </Container>
      </Section>
    )
  }
}

QuoteWithImages.propTypes = {
  quoteCopy: PropTypes.string,
  leftImage: PropTypes.string,
  rightImage: PropTypes.string,
  leftImageCaption: PropTypes.string,
  rightImageCaption: PropTypes.string,
  leftImageDescription: PropTypes.string,
  rightImageDescription: PropTypes.string,
}

export default QuoteWithImages
