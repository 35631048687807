import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import Title from 'components/Title'

import getTestimonialDetails from '../utils/testimonials/getTestimonialDetails'
import { links } from '../styles/links'
import media from '../styles/media'
import VideoPreview from '../components/VideoPreview'
import TestimonialCarousel from '../components/Testimonial/CarouselParent'
import VideoPreviewGrid from '../components/VideoPreviewGrid'
import LogoGroup from '../components/LogoGroup'

const StyledTitle = styled(Title)`
  padding: 96px 48px 70px 134px;

  ${media.tablet`
    padding: 57px 24px 27px;
  `}

  ${media.phone`
    padding: ${({ phonePd }) => phonePd || `57px 36px 3px`};
  `}
`

const ShowreelPage = ({ data }) => {
  const {
    title,
    videos,
    testimonials,
    logos,
    seo_metadata_example_to_include_in_any_type__meta_description,
    seo_metadata_example_to_include_in_any_type__meta_title,
    seo_metadata_example_to_include_in_any_type__opengraph_image,
  } = data.kenticoCloudItemShowreels.elements
  const allLogos = logos[0].elements.logos

  let logosArr = []
  allLogos.forEach(item => {
    if (!item.elements) return

    const logo = {
      url: item.elements.image.assets[0].url,
      alt:
        item.elements.image.assets.length > 0
          ? item.elements.image.assets[0].description
          : '',
    }
    logosArr.push(logo)
  })

  const allTestimonials = testimonials[0].elements.testimonial_group
  let testimonialsArr = []
  allTestimonials.forEach(item => {
    const testimonial = getTestimonialDetails(item)
    if (testimonial) testimonialsArr.push(testimonial)
  })

  return (
    <Layout
      location={links.showreel}
      metaDesc={
        seo_metadata_example_to_include_in_any_type__meta_description.value
      }
      metaTitle={seo_metadata_example_to_include_in_any_type__meta_title.value}
      metaImg={
        seo_metadata_example_to_include_in_any_type__opengraph_image.value[0]
          .url
      }
    >
      <StyledTitle>{title.value}</StyledTitle>
      <VideoPreviewGrid>
        {videos.map(item => (
          <LazyLoad offset={100} key={item.id}>
            <VideoPreview
              isGridItem
              videoUrl={item.elements.video_url.value}
              header={item.elements.title.value}
              thumbnail={item.elements.preview_image.value[0].url}
            />
          </LazyLoad>
        ))}
      </VideoPreviewGrid>
      <StyledTitle phonePd="57px 36px 30px">
        {testimonials[0].system.name}
      </StyledTitle>
      <TestimonialCarousel data={testimonialsArr} isShowreel />
      <LazyLoad offset={100}>
        <LogoGroup logos={logosArr} isShowreel />
      </LazyLoad>
    </Layout>
  )
}

ShowreelPage.propTypes = {
  data: PropTypes.object,
}

export default ShowreelPage

export const showreelQuery = graphql`
  {
    kenticoCloudItemShowreels {
      elements {
        seo_metadata_example_to_include_in_any_type__meta_description {
          value
        }
        seo_metadata_example_to_include_in_any_type__meta_title {
          value
        }
        seo_metadata_example_to_include_in_any_type__opengraph_image {
          value {
            url
          }
        }
        title {
          value
        }
        videos {
          elements {
            preview_image {
              value {
                url
              }
            }
            title {
              value
            }
            video_url {
              value
            }
          }
          internal {
            type
          }
          id
        }
        testimonials {
          internal {
            type
          }
          elements {
            ... on KenticoCloudItemTestimonialGroupElements {
              testimonial_group {
                ... on KenticoCloudItemTestimonial {
                  elements {
                    quote {
                      value
                    }
                    name {
                      value
                    }
                    job_title {
                      value
                    }
                  }
                  id
                  internal {
                    type
                  }
                }
              }
            }
          }
          system {
            name
          }
        }
        logos {
          elements {
            logos {
              ... on KenticoCloudItemLogo {
                elements {
                  image {
                    assets {
                      url
                      description
                    }
                  }
                }
                internal {
                  type
                }
                id
              }
            }
          }
        }
      }
    }
  }
`
