import React from 'react'
import PropTypes from 'prop-types'
import { Container, Column, Section } from './styled'

const TwoColumnText = ({ leftVal, rightVal }) => {
  return (
    <Section>
      <Container>
        <Column dangerouslySetInnerHTML={{ __html: leftVal }} />
        <Column dangerouslySetInnerHTML={{ __html: rightVal }} />
      </Container>
    </Section>
  )
}

TwoColumnText.propTypes = {
  leftVal: PropTypes.string,
  rightVal: PropTypes.string,
}

export default TwoColumnText
