import styled, { css } from 'styled-components'
import media from '../../styles/media'
import { black, white, transparent } from '../../styles/colors'

export const Block = styled.div`
  ${({ isOpen }) => {
    if (isOpen) {
      return css`
        height: 94px;
        width: 100%;
      `
    }
  }}
`

export const Wrapper = styled.div`
  ${({ isOpen }) => {
    if (isOpen) {
      return css`
        position: fixed;
        overflow-y: scroll;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
      `
    }
  }}
`

export const TopBar = styled.div`
  display: flex;
  position: relative;
  justify-items: center;
  justify-content: space-between;
  background-color: ${({ isHomeOrAbout, isHeroImage }) => {
    if (isHeroImage && isHomeOrAbout) return black
    if (isHeroImage && !isHomeOrAbout) return white
    if (!isHeroImage && isHomeOrAbout) return transparent // isHeroVideo on Home or About page
  }};
  padding: 45px 137px 0 143px;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding-bottom: 20px;
  z-index: 999;

  ${media.tablet`
    height: 94px;
    width: 100%;
    padding: 0 24px;
    padding-bottom: 0;
    background-color: ${({ isHeroImage, isHomeOrAbout, isOpen }) => {
      if (!isHeroImage && !isOpen && isHomeOrAbout) return transparent
      return black
    }};
    transition: background-color 0.3s;
    justify-content: center;
    align-items: center;
  `}

  ${media.phone`
    background-color: ${black};
  `}
`

export const InnerWrapper = styled.div`
  display: flex;
  justify-items: center;
  justify-content: space-between;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
`

export const MobileMenu = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: fixed;
  top: 94px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  overflow: visible;
  overflow-y: scroll;
  height: 100vh;
  min-height: calc(100vh - 94px);
  background-color: ${black};
  padding: 52px 0;
  transition: opacity 0.3s;

  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter-active {
    opacity: 1;
  }

  &.fade-exit {
    opacity: 1;
  }

  &.fade-exit-active {
    opacity: 0;
  }
`

export const LinkWrapper = styled.div`
  margin: 113px 0 0 40px;
`

export const BurgerWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  width: 30px;
  left: 24px;
`
