import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import media from '../../styles/media'

const StyledSection = styled.section`
  padding: ${({ pd }) => pd || '0'};
  margin: ${({ mg }) => mg || '0'};

  ${media.phone`
    margin: ${({ mgMob }) => mgMob || '0'};
  `}
`

const Section = ({ children, pd, mg, mgMob }) => {
  return (
    <StyledSection pd={pd} mg={mg} mgMob={mgMob}>
      {children}
    </StyledSection>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  pd: PropTypes.string,
  mg: PropTypes.string,
  mgMob: PropTypes.string,
}

export default Section
