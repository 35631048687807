import React from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import ImageHero from 'components/ImageHero'
import IntroText from 'components/IntroText'
import SummaryBlock from 'components/SummaryBlock'
import SectionBlock from 'components/SectionBlock'
import QuoteWithImages from 'components/QuoteWithImages'
import StatsBreaker from 'components/StatsBreaker'
import LinksSummary from 'components/LinksSummary'
import VideoPreview from 'components/VideoPreview'
import Section from 'components/Section'
import InlineImageWithCaption from 'components/InlineImageWithCaption'
import LogoGroup from 'components/LogoGroup'
import FullWidthImage from 'components/FullWidthImage'
import MiniStatsBreaker from 'components/MiniStatsBreaker'
import TextBlock from 'components/TextBlock'
import TestimonialCarousel from 'components/Testimonial/CarouselParent'
import TwoColumnText from 'components/TwoColumnText'

import getTestimonialDetails from '../utils/testimonials/getTestimonialDetails'
import { links } from '../styles/links'

const WorkDetailPage = ({ data }) => {
  const {
    client_name,
    hero_image,
    hero_title,
    intro_text,
    project_name,
    services,
    summary_text,
    body_text,
    seo_metadata_example_to_include_in_any_type__meta_description,
    seo_metadata_example_to_include_in_any_type__meta_title,
    seo_metadata_example_to_include_in_any_type__opengraph_image,
  } = data.kenticoCloudItemWorkDetailPage.elements
  const { slug } = data.kenticoCloudItemWorkDetailPage.fields

  const buildTestimonialArr = testimonialGroup => {
    const allTestimonials = testimonialGroup
    let testimonialsArr = []
    allTestimonials.forEach(item => {
      const testimonial = getTestimonialDetails(item)
      if (testimonial) testimonialsArr.push(testimonial)
    })

    return testimonialsArr
  }

  return (
    <Layout
      location={`${links.work}/${slug}`}
      metaDesc={
        seo_metadata_example_to_include_in_any_type__meta_description.value
      }
      metaTitle={seo_metadata_example_to_include_in_any_type__meta_title.value}
      metaImg={
        seo_metadata_example_to_include_in_any_type__opengraph_image.value[0]
          .url
      }
    >
      <ImageHero image={hero_image.value[0].url} title={hero_title.value} />
      <IntroText text={intro_text.value} />
      <SummaryBlock
        projectName={project_name.value}
        summary={summary_text.value}
        clientName={client_name.value}
        services={services.value}
      />
      {body_text.linked_items.map(item => {
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemSectionBlock'
        ) {
          return (
            <SectionBlock
              title={item.elements.section_title.value}
              heading={item.elements.heading.value}
              copy={item.elements.body_text.value}
            />
          )
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemWidgetTextBlock'
        ) {
          return <TextBlock copy={item.elements.body_copy.value} />
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemQuoteWithImages'
        ) {
          return (
            <QuoteWithImages
              quoteCopy={item.elements.quote.value}
              leftImage={item.elements.left_image.value[0].url}
              leftImageCaption={item.elements.left_image_caption.value}
              rightImage={item.elements.right_image.value[0].url}
              rightImageCaption={item.elements.right_image_caption.value}
              leftImageDescription={
                item.elements.left_image.value[0].description
              }
              rightImageDescription={
                item.elements.right_image.value[0].description
              }
            />
          )
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemStatsBreaker'
        ) {
          return (
            <StatsBreaker
              title={item.elements.title.value}
              summary={item.elements.summary.value}
              statLabel1={item.elements.stat_label_1.value}
              statValue1={item.elements.stat_value_1.value}
              statLabel2={item.elements.stat_label_2.value}
              statValue2={item.elements.stat_value_2.value}
              statLabel3={item.elements.stat_label_3.value}
              statValue3={item.elements.stat_value_3.value}
            />
          )
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemLinkSummary'
        ) {
          return (
            <LinksSummary
              title={item.elements.title.value}
              category={item.elements.category.value}
              summaryText={item.elements.summary_text.value}
              linkName1={item.elements.link_name_1.value}
              linkUrl1={item.elements.link_url_1.value}
              linkName2={item.elements.link_name_2.value}
              linkUrl2={item.elements.link_url_2.value}
              linkName3={item.elements.link_name_3.value}
              linkUrl3={item.elements.link_url_3.value}
              linkName4={item.elements.link_name_4.value}
              linkUrl4={item.elements.link_url_4.value}
            />
          )
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemVideoPreview'
        ) {
          return (
            <Section mg="100px 0 0" mgMob="36px 0 0">
              <LazyLoad offset={100}>
                <VideoPreview
                  key={item.id}
                  isFullWidth
                  videoUrl={item.elements.video_url.value}
                  header={item.elements.title.value}
                  thumbnail={item.elements.preview_image.value[0].url}
                />
              </LazyLoad>
            </Section>
          )
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemTestimonialGroup'
        ) {
          return (
            <TestimonialCarousel
              isWorkDetail
              data={buildTestimonialArr(item.elements.testimonial_group)}
            />
          )
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemWidgetFullWidthImage'
        ) {
          return (
            <LazyLoad offset={100}>
              <FullWidthImage
                image={item.elements?.image?.value[0]?.url}
                imageName={item.elements?.image?.value[0]?.name}
              />
            </LazyLoad>
          )
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemFullWidthImageWithOverlayText'
        ) {
          return (
            <LazyLoad offset={100}>
              <FullWidthImage
                overlayText={item.elements.overlay_text.value}
                image={item.elements?.image?.value[0]?.url}
                imageName={item.elements?.image?.value[0]?.name}
              />
            </LazyLoad>
          )
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemInlineImageWithCaption'
        ) {
          return (
            <LazyLoad offset={100}>
              <InlineImageWithCaption
                image={
                  item.elements.image.value && item.elements.image.value[0].url
                }
                caption={item.elements.caption.value}
              />
            </LazyLoad>
          )
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemLogoGroup'
        ) {
          const allLogos = item.elements.logos
          let logosArr = []
          allLogos.forEach(item => {
            if (!item.elements) return

            const logo = {
              url: item.elements.image.assets[0].url,
              alt: item.elements.image.assets[0].description,
            }
            logosArr.push(logo)
          })
          return (
            <LazyLoad offset={100}>
              <LogoGroup logos={logosArr} />
            </LazyLoad>
          )
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemMiniStatsBreaker'
        ) {
          const stats = [
            {
              label: item.elements.label_1.value,
              value: item.elements.value_1.value,
            },
            {
              label: item.elements.label_2.value,
              value: item.elements.value_2.value,
            },
            {
              label: item.elements.label_3.value,
              value: item.elements.value_3.value,
            },
          ]
          return <MiniStatsBreaker stats={stats} />
        }
        if (
          item.internal &&
          item.internal.type === 'KenticoCloudItemTwoColumnText'
        ) {
          return (
            <TwoColumnText
              leftVal={item.elements.left_column.value}
              rightVal={item.elements.right_column.value}
            />
          )
        }
      })}
    </Layout>
  )
}

export default WorkDetailPage

WorkDetailPage.propTypes = {
  data: PropTypes.object,
}

export const query = graphql`
  query workDetailQuery($slug: String!) {
    kenticoCloudItemWorkDetailPage(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      elements {
        seo_metadata_example_to_include_in_any_type__meta_description {
          value
        }
        seo_metadata_example_to_include_in_any_type__meta_title {
          value
        }
        seo_metadata_example_to_include_in_any_type__opengraph_image {
          value {
            url
          }
        }
        client_name {
          value
        }
        hero_image {
          value {
            url
          }
        }
        hero_title {
          value
        }
        intro_text {
          value
        }
        project_name {
          value
        }
        services {
          value
        }
        slug {
          value
        }
        summary_text {
          value
        }
        body_text {
          linked_items {
            ... on KenticoCloudItemSectionBlock {
              id
              elements {
                body_text {
                  value
                }
                heading {
                  value
                }
                section_title {
                  value
                }
              }
              internal {
                type
              }
            }
            ... on KenticoCloudItemWidgetTextBlock {
              id
              internal {
                type
              }
              elements {
                body_copy {
                  value
                }
              }
            }
            ... on KenticoCloudItemQuoteWithImages {
              id
              elements {
                left_image {
                  value {
                    url
                  }
                }
                left_image_caption {
                  value
                }
                quote {
                  value
                }
                right_image {
                  value {
                    url
                  }
                }
                right_image_caption {
                  value
                }
              }
              internal {
                type
              }
            }
            ... on KenticoCloudItemStatsBreaker {
              id
              elements {
                title {
                  value
                }
                summary {
                  value
                }
                stat_value_3 {
                  value
                }
                stat_value_2 {
                  value
                }
                stat_value_1 {
                  value
                }
                stat_label_3 {
                  value
                }
                stat_label_2 {
                  value
                }
                stat_label_1 {
                  value
                }
              }
              internal {
                type
              }
            }
            ... on KenticoCloudItemLinkSummary {
              id
              elements {
                title {
                  value
                }
                summary_text {
                  value
                }
                category {
                  value
                }
                link_name_1 {
                  value
                }
                link_name_2 {
                  value
                }
                link_name_3 {
                  value
                }
                link_name_4 {
                  value
                }
                link_url_1 {
                  value
                }
                link_url_2 {
                  value
                }
                link_url_3 {
                  value
                }
                link_url_4 {
                  value
                }
              }
              internal {
                type
              }
            }
            ... on KenticoCloudItemVideoPreview {
              id
              elements {
                preview_image {
                  value {
                    url
                  }
                }
                title {
                  value
                }
                video_url {
                  value
                }
                video_url {
                  value
                }
              }
              internal {
                type
              }
            }
            ... on KenticoCloudItemFullWidthImageWithOverlayText {
              id
              elements {
                image {
                  value {
                    url
                    name
                  }
                }
                overlay_text {
                  value
                }
              }
              internal {
                type
              }
            }
            ... on KenticoCloudItemInlineImageWithCaption {
              id
              elements {
                image {
                  value {
                    url
                  }
                }
                caption {
                  value
                }
              }
              internal {
                type
              }
            }
            ... on KenticoCloudItemLogoGroup {
              id
              internal {
                type
              }
              elements {
                logos {
                  ... on KenticoCloudItemLogo {
                    elements {
                      image {
                        assets {
                          url
                          description
                        }
                      }
                    }
                    internal {
                      type
                    }
                    id
                  }
                }
              }
            }
            ... on KenticoCloudItemTestimonialGroup {
              elements {
                ... on KenticoCloudItemTestimonialGroupElements {
                  testimonial_group {
                    ... on KenticoCloudItemTestimonial {
                      elements {
                        job_title {
                          value
                        }
                        name {
                          value
                        }
                        quote {
                          value
                        }
                      }
                    }
                  }
                }
              }
              id
              internal {
                type
              }
            }
            ... on KenticoCloudItemWidgetFullWidthImage {
              id
              elements {
                image {
                  value {
                    url
                    name
                  }
                }
              }
              internal {
                type
              }
            }
            ... on KenticoCloudItemFullWidthImageWithOverlayText {
              id
              elements {
                image {
                  value {
                    url
                    name
                  }
                }
                overlay_text {
                  value
                }
              }
              internal {
                type
              }
            }
            ... on KenticoCloudItemTwoColumnText {
              id
              elements {
                left_column {
                  value
                }
                right_column {
                  value
                }
              }
              internal {
                type
              }
            }
            ... on KenticoCloudItemMiniStatsBreaker {
              id
              elements {
                label_1 {
                  value
                }
                label_2 {
                  value
                }
                label_3 {
                  value
                }
                value_1 {
                  value
                }
                value_2 {
                  value
                }
                value_3 {
                  value
                }
              }
              internal {
                type
              }
            }
          }
        }
      }
    }
  }
`
