import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import {
  VimeoWrapper,
  Iframe,
  Scrim,
  // OverlayText,
} from './styled'
// import { allEmptyTextValues } from '../../styles/const'

class HeroVideo extends React.Component {
  state = {
    videoRatio: 16 / 9,
    videoId: null,
  }

  componentDidMount = async () => {
    const { videoId } = this.props

    this.setState({ videoId })

    try {
      const res = await axios.get(
        `https://vimeo.com/api/oembed.json?url=http%3A%2F%2Fvimeo.com%2F${videoId}`
      )
      const videoData = res.data
      this.setState({ videoRatio: videoData.width / videoData.height })
    } catch (err) {
      return null
    }
  }

  render() {
    const { videoRatio } = this.state
    let {
      videoId,
      // text,
    } = this.state

    if (videoId && videoId.includes('/')) {
      videoId = videoId.split('/')[0]
    }

    // We are not allowing any overlay text on video at the moment
    // const isTextEmpty = allEmptyTextValues.some(value => text === value)

    return (
      <VimeoWrapper videoRatio={videoRatio}>
        {videoId && (
          <>
            <Iframe
              src={`https://player.vimeo.com/video/${videoId}?background=1&autoplay=1&loop=1&byline=0&title=0&sidedock=0&controls=0&frameborder=0`}
              frameborder="0"
              title="0"
              sidedock="0"
              controls="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
            ></Iframe>
            <Scrim />

            {/* {!isTextEmpty && (
            <>
              <Scrim />
              <OverlayText dangerouslySetInnerHTML={{ __html: text }} />
            </>
          )} */}
          </>
        )}
      </VimeoWrapper>
    )
  }
}

HeroVideo.propTypes = {
  videoId: PropTypes.string,
  text: PropTypes.string,
}

export default HeroVideo
