import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { animateScroll as scroll } from 'react-scroll'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled, { ThemeProvider, css } from 'styled-components'
import { links } from '../styles/links'
import GlobalStyle from '../styles/globalStyle'
import Header from '../components/Header'
import Footer from '../components/Footer'
import logoBlack from '../assets/logos/logo-black.svg'
import logoWhite from '../assets/logos/logo-white.svg'
import Instagram from '../assets/icons/instagram.svg'
import LinkedIn from '../assets/icons/linkedin.svg'
import { black, white, red } from '../styles/colors'
import media from '../styles/media'
import CookieConsent from 'react-cookie-consent'

const App = styled.div`
  font-family: 'Avenir Next', 'Helvetica Neue', 'Helvetica', sans-serif;
  font-weight: 500;
  min-height: calc(100vh - 447px - 76px);
  max-width: 1920px;
  margin: 0 auto;

  ${media.phone`
    min-height: calc(100vh - 708px - 94px);
  `}

  ${({ isHeroImage, isHomeOrAbout }) =>
    !isHeroImage &&
    isHomeOrAbout &&
    css`
      position: relative;
      top: -95px;

      ${media.phone`
        position: static;
      `}
    `}
`

const theme = {
  space: [0, 6, 12, 18, 24],
  breakpoints: ['767px', '1280px', '1920px'],
}

class Layout extends React.Component {
  handleScrollToTop = () => {
    scroll.scrollToTop()
  }

  render() {
    const {
      children,
      location,
      metaDesc,
      metaTitle,
      metaImg,
      isHeroImage,
    } = this.props

    return (
      <StaticQuery
        query={graphql`
          {
            kenticoCloudItemMasterPage {
              elements {
                seo_metadata_example_to_include_in_any_type__meta_description {
                  value
                }
                seo_metadata_example_to_include_in_any_type__meta_title {
                  value
                }
                seo_metadata_example_to_include_in_any_type__opengraph_image {
                  value {
                    url
                  }
                }
                about_nav_item {
                  value
                }
                blog_nav_item {
                  value
                }
                contact_nav_item {
                  value
                }
                footer_cta_button_label {
                  value
                }
                footer_cta_button_target {
                  value
                }
                footer_cta_description {
                  value
                }
                footer_cta_title {
                  value
                }
                imprint_footer_nav_item {
                  value
                }
                people_nav_item {
                  value
                }
                privacy_footer_nav_item {
                  value
                }
                showreel_nav_item {
                  value
                }
                work_nav_item {
                  value
                }
                linkedin_url {
                  value
                }
                instagram_url {
                  value
                }
              }
            }
          }
        `}
        render={data => {
          const elements = data.kenticoCloudItemMasterPage.elements
          const instaUrl = elements.instagram_url.value
          const linkedInUrl = elements.linkedin_url.value
          const header = elements.footer_cta_title.value
          const cta = elements.footer_cta_description.value
          const navItems = [
            {
              url: links.about,
              label: elements.about_nav_item.value,
            },
            {
              url: links.work,
              label: elements.work_nav_item.value,
            },
            {
              url: links.people,
              label: elements.people_nav_item.value,
            },
            {
              url: links.showreel,
              label: elements.showreel_nav_item.value,
            },
            {
              url: links.blog,
              label: elements.blog_nav_item.value,
            },
            {
              url: links.contact,
              label: elements.contact_nav_item.value,
            },
          ]
          const privacyItems = [
            {
              url: links.imprint,
              label: elements.imprint_footer_nav_item.value,
            },
            {
              url: links.dataProtection,
              label: elements.privacy_footer_nav_item.value,
            },
          ]
          const socialLinks = [
            {
              name: 'instagram',
              logo: Instagram,
              url: elements.instagram_url.value,
            },
            {
              name: 'linkedIn',
              logo: LinkedIn,
              url: elements.linkedin_url.value,
            },
          ]
          const isHomeOrAbout =
            location === links.home || location === links.about

          const masterMetaTitle =
            elements.seo_metadata_example_to_include_in_any_type__meta_title
              .value
          const masterMetaDesc =
            elements
              .seo_metadata_example_to_include_in_any_type__meta_description
              .value
          const masterMetaImg =
            elements
              .seo_metadata_example_to_include_in_any_type__opengraph_image
              .value[0].url

          return (
            <ThemeProvider theme={theme}>
              <>
                <Helmet
                  title={metaTitle ? metaTitle : masterMetaTitle}
                  description={metaDesc ? metaDesc : masterMetaDesc}
                  meta={[
                    {
                      property: `og:title`,
                      content: metaTitle ? metaTitle : masterMetaTitle,
                    },
                    {
                      property: `og:image`,
                      content: metaImg ? metaImg : masterMetaImg,
                    },
                    {
                      property: `og:description`,
                      content: metaDesc ? metaDesc : masterMetaDesc,
                    },
                  ]}
                />
                <GlobalStyle />
                <Header
                  logo={isHomeOrAbout ? logoWhite : logoBlack}
                  logoMob={logoWhite}
                  navItems={navItems}
                  instaUrl={instaUrl}
                  linkedInUrl={linkedInUrl}
                  location={location}
                  color={isHomeOrAbout ? white : black}
                  bgColor={isHomeOrAbout ? black : white}
                  isHeroImage={isHeroImage}
                  isHomeOrAbout={isHomeOrAbout}
                />
                <App isHeroImage={isHeroImage} isHomeOrAbout={isHomeOrAbout}>
                  {children}
                </App>
                <CookieConsent
                  buttonStyle={{
                    color: white,
                    backgroundColor: red,
                    borderRadius: '50px',
                    padding: '10px 20px',
                  }}
                  style={{
                    background: black,
                    fontFamily: 'Gilroy',
                  }}
                >
                  Black Paint uses cookies to enhance the user experience. By
                  continuing to browse the site you are agreeing to our use of
                  cookies.&nbsp;
                  <u>
                    <Link to={links.dataProtection}>
                      More information on our use of cookies
                    </Link>
                  </u>
                </CookieConsent>
                <Footer
                  header={header}
                  cta={cta}
                  logo={logoWhite}
                  socialLinks={socialLinks}
                  navItems={navItems}
                  privacyItems={privacyItems}
                  handleScrollToTop={this.handleScrollToTop}
                />
              </>
            </ThemeProvider>
          )
        }}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.string,
  metaDesc: PropTypes.string,
  metaTitle: PropTypes.string,
  metaImg: PropTypes.string,
  isHeroImage: PropTypes.bool,
}

export default Layout
