import styled from 'styled-components'
import media from '../../styles/media'
import { lightGrey } from '../../styles/colors'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  padding-left: calc((100vw - 530px) / 2);
  width: 100vw;
  left: 50%;
  right: 50%;
  margin: 100px -50vw 0 -50vw;
  max-width: 1920px;

  ${media.phone`
    padding: 0 36px;
    margin: 36px -50vw 0 -50vw;
  `}

  ${media.desktopXl`
    padding-left: 92px;
    left: 0;
    width: 856px;
    margin: 100px auto 0;
  `}
`

export const InnerWrapper = styled.div`
  position: relative;
`

export const Image = styled.img`
  width: 812px;

  ${media.tablet`
    width: 700px;
  `}

  ${media.phone`
    width: 100%;
  `}

  ${media.desktopXl`
    width: 724px;
  `}
`

export const Line = styled.div`
  position: absolute;
  top: 20%;
  z-index: -1;
  width: 100%;
  border: 1.5px solid ${lightGrey};

  ${media.phone`
    display: none;
  `}
`

export const Caption = styled.div`
  border-left: 3px solid ${lightGrey};
  border-bottom: 3px solid ${lightGrey};
  padding: 30px 36px;
  font-family: Gilroy;
  font-size: 14px;
  letter-spacing: 2.1px;
  font-weight: 600;
  text-transform: uppercase;

  ${media.phone`
    padding: 25px;
    border: 3px solid ${lightGrey};
    border-top: none;
  `}
`
