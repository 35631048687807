import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Swiper from 'react-id-swiper'

import './style.css'
import openingQuoteMark from '../../assets/icons/opening_quote_mark.png'
import closingQuoteMark from '../../assets/icons/closing_quote_mark.png'
import arrowLeft from '../../assets/icons/arrow_left_red.png'
import arrowRight from '../../assets/icons/arrow_right_red.png'

import {
  Section,
  QuoteContainer,
  QuoteOuter,
  QuoteWrapper,
  PersonWrapper,
  OpeningMark,
  ClosingMark,
  Mark,
  PersonName,
  PersonJobTitle,
  PersonSliderWrapper,
  SlideNav,
  SlideCounter,
  SlideArrowsWrapper,
  SlideArrow,
  Container,
  LabelWrapper,
  SlideContainer,
  QuoteInner,
  BorderBox,
} from './styled'

const Carousel = ({
  data,
  isHome,
  isShowreel,
  goPrevParent,
  goNextParent,
  counter,
  jobTitle,
  name,
}) => {
  const [swiper, updateSwiper] = useState(null)
  const [isFadeIn, setIsFadeIn] = useState(false)

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev()
    }
  }

  const itemCounterFormatter = i => {
    let index = i + 1
    let total = data.length
    if (total < 10) total = '0' + total
    if (index < 10) index = '0' + index
    return `${index}/${total}`
  }

  const swiperParams = {
    slidesPerView: 1,
    centeredSlides: true,
    loop: false,
    getSwiper: updateSwiper,
    on: {
      slideNextTransitionStart: function() {
        setIsFadeIn(true)
        setTimeout(() => {
          setIsFadeIn(false)
        }, 500)
        goNextParent()
        goNext()
      },
      slidePrevTransitionStart: function() {
        setIsFadeIn(true)
        setTimeout(() => {
          setIsFadeIn(false)
        }, 500)
        goPrevParent()
        goPrev()
      },
    },
  }

  const totalSlides = data.length
  const isMoreThanOneSlide = totalSlides > 1
  return (
    <Section>
      <Container isHome={isHome} isShowreel={isShowreel}>
        <QuoteContainer>
          <BorderBox isMoreThanOneSlide={isMoreThanOneSlide} isHome={isHome}>
            <Swiper {...swiperParams}>
              {data.map((item, i) => {
                const quote = item.quote
                return (
                  <QuoteOuter key={i}>
                    <QuoteInner>
                      <div style={{ position: 'relative', padding: '0 36px' }}>
                        <OpeningMark>
                          <Mark src={openingQuoteMark} alt={`"`} />
                        </OpeningMark>
                        <QuoteWrapper
                          dangerouslySetInnerHTML={{ __html: quote }}
                        />
                        <ClosingMark>
                          <Mark src={closingQuoteMark} alt={`"`} />
                        </ClosingMark>
                      </div>
                    </QuoteInner>
                  </QuoteOuter>
                )
              })}
            </Swiper>
          </BorderBox>
        </QuoteContainer>
        <LabelWrapper>
          <PersonSliderWrapper isMoreThanOneSlide={isMoreThanOneSlide}>
            <SlideNav>
              {isMoreThanOneSlide && (
                <>
                  <SlideCounter>
                    {itemCounterFormatter(counter - 1)}
                  </SlideCounter>
                  <SlideArrowsWrapper>
                    <SlideArrow onClick={() => goPrev()}>
                      <img src={arrowLeft} />
                    </SlideArrow>
                    <SlideArrow onClick={() => goNext()}>
                      <img src={arrowRight} />
                    </SlideArrow>
                  </SlideArrowsWrapper>
                </>
              )}
            </SlideNav>
            <PersonWrapper isFadeIn={isFadeIn}>
              <PersonName>{name}</PersonName>
              <PersonJobTitle>{jobTitle}</PersonJobTitle>
            </PersonWrapper>
          </PersonSliderWrapper>
        </LabelWrapper>
        {isMoreThanOneSlide && (
          <>
            <SlideContainer>
              <SlideCounter>{itemCounterFormatter(counter - 1)}</SlideCounter>
              <SlideArrowsWrapper>
                <SlideArrow onClick={() => goPrev()}>
                  <img src={arrowLeft} />
                </SlideArrow>
                <SlideArrow onClick={() => goNext()}>
                  <img src={arrowRight} />
                </SlideArrow>
              </SlideArrowsWrapper>
            </SlideContainer>
          </>
        )}
      </Container>
    </Section>
  )
}

Carousel.defaultProps = {
  data: [],
  isHome: false,
  isShowreel: false,
}

Carousel.propTypes = {
  data: PropTypes.array,
  isHome: PropTypes.bool,
  isShowreel: PropTypes.bool,
  goPrevParent: PropTypes.func,
  goNextParent: PropTypes.func,
  counter: PropTypes.number,
  jobTitle: PropTypes.string,
  name: PropTypes.string,
}

export default Carousel
