import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Category, Title, Subtitle, Scrim } from './styled'
import { links } from '../../styles/links'

const ImageHero = ({ image, title, subtitle, category, location }) => {
  const isAbout = location === links.about

  return (
    <Wrapper isAbout={isAbout} style={{ backgroundImage: `url("${image}")` }}>
      <Scrim isAbout={isAbout} />
      {category && <Category>{category}</Category>}
      <Title isAbout={isAbout}>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Wrapper>
  )
}

ImageHero.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  category: PropTypes.string,
  location: PropTypes.string,
}

export default ImageHero
