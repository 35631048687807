import React, { useState } from 'react'
import PropTypes from 'prop-types'
import LatestWorkCard from './Card'
import { Section, Controls, Control } from './styled'
import Swiper from 'react-id-swiper'

import './style.css'
import { black, white } from '../../styles/colors'

const Carousel = ({ data }) => {
  const [swiper, updateSwiper] = useState(null)
  const [rightArrow, setRightArrow] = useState(white)
  const [leftArrow, setLeftArrow] = useState(white)
  const [sliderIndex, setSliderIndex] = useState(0)
  const lastSlideIndex = data.length - 1

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
    if (sliderIndex !== lastSlideIndex) {
      setSliderIndex(sliderIndex + 1)
    }
  }

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev()
    }
    if (sliderIndex !== 0) {
      setSliderIndex(sliderIndex - 1)
    }
  }

  const swiperParams = {
    slidesPerView: 1,
    centeredSlides: true,
    loop: false,
    getSwiper: updateSwiper,
    pagination: {
      el: '.swiper-pagination',
    },
  }

  return (
    <Section>
      <Swiper {...swiperParams}>
        {data.map((item, i) => {
          return (
            <div key={i}>
              <LatestWorkCard
                introText={item.introText}
                slug={item.slug}
                heroTitle={item.heroTitle}
                imageUrl={item.imageUrl}
              />
            </div>
          )
        })}
      </Swiper>
      <Controls>
        <Control
          onClick={() => goPrev()}
          onMouseEnter={() => setLeftArrow(black)}
          onMouseLeave={() => setLeftArrow(white)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="10"
            viewBox="0 0 14 10"
          >
            <g>
              <g clipPath="url(#clip-cdca2e10-77c8-4cb1-8568-f1a1129f121b)">
                <path
                  fill={leftArrow}
                  d="M4.572.15a.49.49 0 01.706 0c.19.198.19.512 0 .71L1.7 4.496h11.805a.496.496 0 01.495.502c0 .278-.22.509-.495.509H1.7l3.578 3.626c.19.201.19.516 0 .717a.49.49 0 01-.706 0L.148 5.355a.497.497 0 010-.708L4.572.15z"
                />
              </g>
            </g>
          </svg>
        </Control>
        <Control
          onClick={goNext}
          onMouseEnter={() => setRightArrow(black)}
          onMouseLeave={() => setRightArrow(white)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="10"
            viewBox="0 0 14 10"
          >
            <g>
              <g clipPath="url(#clip-cdca2e10-77c8-4cb1-8568-f1a1129f121b)">
                <path
                  fill={rightArrow}
                  d="M9.428.15a.49.49 0 0 0-.706 0 .513.513 0 0 0 0 .71L12.3 4.496H.495A.496.496 0 0 0 0 4.998c0 .278.22.509.495.509H12.3L8.722 9.133a.521.521 0 0 0 0 .717c.198.2.515.2.706 0l4.424-4.495a.497.497 0 0 0 0-.708z"
                />
              </g>
            </g>
          </svg>
        </Control>
      </Controls>
    </Section>
  )
}

Carousel.defaultProps = {
  data: [],
}

Carousel.propTypes = {
  data: PropTypes.array,
}

export default Carousel
