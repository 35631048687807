import styled from 'styled-components'
import media from '../../styles/media'
import { red, black, white } from '../../styles/colors'

export const Wrapper = styled.div`
  position: relative;
`

export const Image = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${black};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${media.phone`
    height: 250px;
  `}
`

export const OverlayText = styled.div`
  font-family: 'Gilroy';
  font-size: 48px;
  letter-spacing: -0.48px;
  line-height: 66px;
  font-weight: 300;
  color: ${white};
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
  padding: 50px;

  & strong {
    color: ${red};
    font-weight: 600;
  }

  & em {
    font-style: italic;
  }

  ${media.phone`
    padding: 36px;
    font-size: 30px;
    letter-spacing: -0.3px;
    line-height: 35px;
  `}
`

export const Scrim = styled.div`
  background-color: ${black};
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`
