import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from './styled'

const TextBlock = ({ copy }) => {
  return (
    <Wrapper
      dangerouslySetInnerHTML={{
        __html: copy,
      }}
    />
  )
}

TextBlock.propTypes = {
  copy: PropTypes.string,
}

export default TextBlock
