import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import Title from 'components/Title'
import PeopleGrid from 'components/PeopleGrid'
import FullWidthImage from 'components/FullWidthImage'

import { links } from '../styles/links'
import media from '../styles/media'
import { red } from '../styles/colors'
// import { allEmptyTextValues } from '../styles/const'

const StyledTitle = styled(Title)`
  padding: 100px 48px 80px;

  ${media.tablet`
    padding: 45px 0 42px;
  `}

  ${media.phone`
    padding: 45px 0 18px;
  `}
`

const TramLines = styled.div`
  width: 100%;
  padding: 0 91px;

  ${media.tablet`
    padding: 0 86px;
  `}

  ${media.phone`
    padding: 0 36px;
  `}
`

const DividerText = styled.div`
  font-family: 'Gilroy';
  max-width: 720px;
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 40px;
  ${({ isMt }) => isMt && 'margin-top: 60px'};

  ${media.tablet`
    width: 100%;
    margin: 0 auto;
    margin-bottom: 40px;
  `}

  ${media.phone`
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
  `}
`

const TextWrapper = styled.div`
  margin-left: 20%;

  ${media.tablet`
    margin-left: auto;
  `}
`

const TextWrapperInner = styled.div`
  margin-left: calc(33.3% - 260px);

  ${media.tablet`
    margin-left: auto;
  `}
`

const SubHeading = styled.h2`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2.1px;
  line-height: 41px;
  text-transform: uppercase;
  color: ${red};
  margin-bottom: 50px;
  padding: 0 48px;

  ${media.tablet`
    padding: 0;
  `}
`

const PeoplePage = ({ data }) => {
  const {
    team,
    network,
    title,
    full_width_image,
    // network_intro_text,
    team_intro_text,
    seo_metadata_example_to_include_in_any_type__meta_description,
    seo_metadata_example_to_include_in_any_type__meta_title,
    seo_metadata_example_to_include_in_any_type__opengraph_image,
  } = data.kenticoCloudItemPeople.elements

  let peopleArr = []
  team.forEach(item => {
    const member = {
      image:
        item.elements.profile_image.value.length > 0 &&
        item.elements.profile_image.value[0].url,
      name: item.elements.name.value,
      jobTitle: item.elements.job_title.value,
      quote: item.elements.quote.value,
      summary: item.elements.summary.value,
      id: item.id,
    }
    peopleArr.push(member)
  })

  let networkArr = []
  network.forEach(item => {
    const member = {
      image:
        item.elements.profile_image.value.length > 0 &&
        item.elements.profile_image.value[0].url,
      name: item.elements.name.value,
      jobTitle: item.elements.job_title.value,
      quote: item.elements.quote.value,
      summary: item.elements.summary.value,
      id: item.id,
    }
    networkArr.push(member)
  })
  // for later use
  // const isEmptyTeamIntro = allEmptyTextValues.some(
  //   value => value === team_intro_text.value
  // )
  // const isEmptyNetworkIntro = allEmptyTextValues.some(
  //   value => value === network_intro_text.value
  // )
  return (
    <Layout
      location={links.people}
      metaDesc={
        seo_metadata_example_to_include_in_any_type__meta_description.value
      }
      metaTitle={seo_metadata_example_to_include_in_any_type__meta_title.value}
      metaImg={
        seo_metadata_example_to_include_in_any_type__opengraph_image.value[0]
          .url
      }
    >
      <TramLines>
        <StyledTitle>{title.value}</StyledTitle>
        <TextWrapper>
          <TextWrapperInner>
            <DividerText>
              <div
                dangerouslySetInnerHTML={{ __html: team_intro_text.value }}
              />
            </DividerText>
          </TextWrapperInner>
        </TextWrapper>
        <SubHeading>Leadership Team</SubHeading>
        <PeopleGrid people={peopleArr} />
        {/* <TextWrapper>
          <TextWrapperInner>
            <DividerText isMt>
              <div
                dangerouslySetInnerHTML={{ __html: network_intro_text.value }}
              />
            </DividerText>
          </TextWrapperInner>
        </TextWrapper> */}
        {/* <SubHeading>Global Network</SubHeading> */}
        {/* <PeopleGrid people={networkArr} /> */}
      </TramLines>
      <LazyLoad offset={100}>
        <FullWidthImage image={full_width_image.value[0].url} />
      </LazyLoad>
    </Layout>
  )
}

PeoplePage.propTypes = {
  data: PropTypes.object,
}

export default PeoplePage

export const query = graphql`
  {
    kenticoCloudItemPeople {
      elements {
        seo_metadata_example_to_include_in_any_type__meta_description {
          value
        }
        seo_metadata_example_to_include_in_any_type__meta_title {
          value
        }
        seo_metadata_example_to_include_in_any_type__opengraph_image {
          value {
            url
          }
        }
        full_width_image {
          value {
            url
          }
        }
        network_intro_text {
          value
        }
        team_intro_text {
          value
        }
        network {
          elements {
            profile_image {
              value {
                url
              }
            }
            job_title {
              value
            }
            name {
              value
            }
            quote {
              value
            }
            summary {
              value
            }
          }
          id
        }
        team {
          elements {
            profile_image {
              value {
                url
              }
            }
            job_title {
              value
            }
            name {
              value
            }
            quote {
              value
            }
            summary {
              value
            }
          }
          id
        }
        title {
          value
        }
      }
    }
  }
`
