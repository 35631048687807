import styled from 'styled-components'
import { red, black } from '../../styles/colors'
import media from '../../styles/media'

export const BlockContainer = styled.div`
  display: flex;
  flex-flow: row;
  font-family: 'Gilroy';
  width: 53%;
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 30px;

  ${media.tablet`
    width: 100%;
    max-width: 720px;
  `}

  ${media.phone`
    flex-flow: column;
  `}
`

export const MetaDataContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 40%;
  padding-right: 40px;

  ${media.phone`
    text-align: center;
    margin: 0 auto;
    padding-right: 0;
    width: 67%;
    max-width: 250px;
  `}
`

export const SummaryContainer = styled.div`
  display: flex;
  width: 60%;
  padding-left: 10px;

  ${media.tablet`
    width: 100%;
    max-width: 720px;
  `}

  ${media.phone`
    margin: 0 auto;
    padding-left: 0;
    padding: 0 36px;
    margin: 0 auto;
  `}
`

export const DatumContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 30px;
`

export const DatumName = styled.h4`
  text-transform: uppercase;
  color: ${red};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2.4px;
  line-height: 41px;
`

export const Datum = styled.h3`
  color: ${black};
  font-weight: 400;

  font-size: 18px;
  line-height: 32px;

  ${media.phone`
    font-size: 18px;
  `}
`

export const Summary = styled.div`
  color: ${black};
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;

  ${media.phone`
    line-height: 30px;
  `}
`

export const Separator = styled.div`
  ${media.phone`
    width: 42px;
    height: 1px;
    background-color: ${red};
    margin: 40px auto 70px;
  `}
`
