import styled from 'styled-components'
import media from '../../styles/media'
import { black, red } from '../../styles/colors'

export const Container = styled.div`
  max-width: 720px;
  font-family: 'Gilroy';
  padding-left: 48px;
  padding-bottom: 100px;

  ${media.tablet`
    padding-left: 24px;
  `}

  ${media.phone`
    padding-left: 18px;
    padding-bottom: 50px;
  `}
`

export const IntroText = styled.div`
  color: ${black};
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 40px;

  ${media.phone`
    font-size: 20px;
    line-height: 30px;
    max-width: 98%;
  `}
`

export const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  white-space: pre-line;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.27px;
  line-height: 30px;

  ${media.phone`
    flex-flow: column;
    font-size: 18px;
    letter-spacing: -0.24px;
  `}
`

export const EmailAndPhone = styled.div`
  white-space: initial;

  & a[href^='mailto'] {
    color: ${red};
  }

  ${media.phone`
    margin-bottom: 30px;
  `}
`

export const Address = styled.div`
  white-space: initial;

  & a {
    color: ${red};
  }

  ${media.phone`
    line-height: 29px;
  `}
`
