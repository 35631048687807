import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import Button from 'components/Button'
import {
  Wrapper,
  ProjectProfile,
  Heading,
  Label,
  Tagline,
  Scrim,
} from './styled'

class LatestWorkCard extends Component {
  render() {
    const { introText, slug, heroTitle, imageUrl } = this.props

    return (
      <Wrapper style={{ backgroundImage: `url("${imageUrl}")` }}>
        <Scrim />
        <ProjectProfile>
          <Heading>Latest</Heading>
          <Label>{heroTitle}</Label>
          <Tagline>{introText}</Tagline>
          <Link to={`/work/${slug}`}>
            <Button width="152px">Read more</Button>
          </Link>
        </ProjectProfile>
      </Wrapper>
    )
  }
}

LatestWorkCard.propTypes = {
  introText: PropTypes.string,
  slug: PropTypes.string,
  heroTitle: PropTypes.string,
  imageUrl: PropTypes.string,
}

export default LatestWorkCard
