import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from './styled'
import Arrow from '../../assets/icons/arrow.svg'

const Button = ({ children, fSize, width }) => {
  return (
    <Wrapper fSize={fSize} width={width}>
      {children}
      <img src={Arrow} />
    </Wrapper>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  fSize: PropTypes.string,
  width: PropTypes.string,
}

export default Button
