import styled from 'styled-components'
import media from '../../styles/media'
import { grey, red, white } from '../../styles/colors'

export const Img = styled.img`
  width: 100%;
  background-color: ${grey};
  margin-top: ${({ noMt }) => !noMt && '100px'};

  ${media.phone`
    margin-top: ${({ noMt }) => !noMt && '36px'};
  `}
`

export const TextContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 230px;
  margin-top: -115px;

  ${media.tablet`
    height: 145px;
    margin-top: -75px;
  `}

  ${media.phone`
    height: 240px;
    margin-top: -75px;
  `}
`

export const TextWrapper = styled.div`
  background-color: ${red};
  width: 82%;
  padding: 54px 78px;
  display: flex;
  align-items: center;

  ${media.tablet`
    padding: 26px 44px;
  `}

  ${media.phone`
    padding: 16px 30px;
  `}
`

export const Text = styled.p`
  font-family: 'Gilroy';
  color: ${white};
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  max-width: 819px;

  ${media.tablet`
    line-height: 30px;
    font-size: 18px;
    max-width: 719px;
  `}
`
