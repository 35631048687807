import styled from 'styled-components'
import { red, lightGrey } from '../../styles/colors'
import media from '../../styles/media'

export const Section = styled.section`
  font-family: 'Gilroy';
  width: 100%;
  padding: 147px 0 117px 0;
  background-color: ${lightGrey};
  margin-top: 120px;

  ${media.phone`
    margin-top: 36px;
    padding: 57px 36px 16px;
  `}
`

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  width: 53%;
  max-width: 900px;
  margin: 0 auto;

  ${media.tablet`
    width: 100%;
    max-width: 720px;
  `}

  ${media.phone`
    width: 100%;
    flex-flow: column;
  `}
`

export const Wrapper = styled.div`
  padding-left: 45%;

  ${media.phone`
    padding-left: 0;
  `}
`

export const Label = styled.p`
  position: absolute;
  color: ${red};
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2.4px;
  line-height: 41px;

  ${media.phone`
    position: static;
    margin-bottom: 16px;
  `}
`

export const Copy = styled.p`
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 50px;

  ${media.phone`
    line-height: 30px;
  `}
`

export const Stats = styled.div`
  display: flex;

  ${media.phone`
    flex-flow: column;
    align-items: center;
  `}
`

export const Stat = styled.div`
  margin-right: 60px;

  ${media.phone`
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 0 0 40px 0;
  `}
`

export const Value = styled.p`
  color: ${red};
  font-size: 48px;
  font-weight: 600;
  letter-spacing: -0.48px;
  line-height: 54px;
`

export const StatLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2.1px;
  line-height: 25px;
  text-transform: uppercase;
`
