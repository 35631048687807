import React from 'react'
import PropTypes from 'prop-types'
import { Img, TextContainer, TextWrapper, Text } from './styled'

const FullWidthImage = ({ image, imageName, overlayText, noMt }) => {
  return (
    <>
      <Img src={image} alt={imageName || 'full-width image'} noMt={noMt} />
      {overlayText && (
        <TextContainer>
          <TextWrapper>
            <Text dangerouslySetInnerHTML={{ __html: overlayText }} />
          </TextWrapper>
        </TextContainer>
      )}
    </>
  )
}

FullWidthImage.propTypes = {
  image: PropTypes.string,
  imageName: PropTypes.string,
  overlayText: PropTypes.string,
  noMt: PropTypes.bool,
}

export default FullWidthImage
