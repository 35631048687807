import React from 'react'
import PropTypes from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import GatsbyLink from '../GatsbyLink'
import Logo from '../Logo'
import Button from '../Button'
import SocialLink from '../SocialLink'
import withCurrentBreakpoint, {
  BREAKPOINTS,
} from '../../hoc/withCurrentBreakpoint'
import Hamburger from 'components/Hamburger'
import Instagram from '../../assets/icons/instagram.svg'
import LinkedIn from '../../assets/icons/linkedin.svg'
import {
  Wrapper,
  TopBar,
  InnerWrapper,
  MobileMenu,
  LinkWrapper,
  BurgerWrapper,
  Block,
} from './styled'
import { linkTypes } from '../../styles/links'

class Header extends React.Component {
  state = {
    isOpen: false,
  }

  toggleMenu = () => {
    this.setState({ isOpen: !this.state.isOpen }, () => {
      if (this.state.isOpen) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'scroll'
      }
    })
  }

  componentWillUnmount() {
    document.body.style.overflow = 'scroll'
  }

  closeMenu = () => {
    this.setState({ isOpen: false })
  }

  render() {
    const {
      logo,
      navItems,
      color,
      currentBreakpoint,
      logoMob,
      instaUrl,
      linkedInUrl,
      location,
      forSb,
      isHeroImage,
      isHomeOrAbout,
    } = this.props
    const { isOpen } = this.state
    const mobileDisplays = [BREAKPOINTS.phone, BREAKPOINTS.tablet]
    const isMobile = mobileDisplays.includes(currentBreakpoint)

    return (
      <>
        <Block isOpen={isOpen} />
        <Wrapper isOpen={isOpen}>
          <TopBar
            isOpen={isOpen}
            isHomeOrAbout={isHomeOrAbout}
            isHeroImage={isHeroImage}
          >
            {isMobile ? (
              <>
                <BurgerWrapper onClick={this.toggleMenu}>
                  <Hamburger />
                </BurgerWrapper>
                <Logo logo={logoMob} />
              </>
            ) : (
              <InnerWrapper>
                <Logo logo={logo} />
                <div>
                  {navItems.map((item, i) => (
                    <GatsbyLink
                      linkType={linkTypes.headerLink}
                      to={item.url}
                      label={item.label}
                      color={color}
                      key={i}
                      location={location}
                      closeMenu={this.closeMenu}
                    />
                  ))}
                </div>
              </InnerWrapper>
            )}
          </TopBar>
          <TransitionGroup>
            {isMobile && isOpen && (
              <CSSTransition classNames="fade" timeout={300} unmountOnExit>
                <MobileMenu isOpen={isOpen}>
                  {navItems.map((item, i) => (
                    <GatsbyLink
                      linkType={linkTypes.headerLink}
                      to={item.url}
                      label={item.label}
                      color={color}
                      key={i}
                      location={location}
                      closeMenu={this.closeMenu}
                      forSb={forSb}
                    />
                  ))}
                  <LinkWrapper>
                    <Button>Get in touch</Button>
                    <SocialLink to={instaUrl} logo={Instagram} />
                    <SocialLink to={linkedInUrl} logo={LinkedIn} />
                  </LinkWrapper>
                </MobileMenu>
              </CSSTransition>
            )}
          </TransitionGroup>
        </Wrapper>
      </>
    )
  }
}

Header.propTypes = {
  logo: PropTypes.string,
  navItems: PropTypes.array,
  color: PropTypes.string,
  currentBreakpoint: PropTypes.string,
  logoMob: PropTypes.string,
  instaUrl: PropTypes.string,
  linkedInUrl: PropTypes.string,
  location: PropTypes.string,
  forSb: PropTypes.bool,
  isHeroImage: PropTypes.bool,
  isHomeOrAbout: PropTypes.bool,
}

export default withCurrentBreakpoint(Header)
