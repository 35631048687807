import styled from 'styled-components'
import media from '../../styles/media'

export const Section = styled.section`
  /* Subtracts the side margin inherent to the video preview component */
  padding: 0 calc(140px - 46px) 70px;
  margin-top: -40px;

  ${media.tablet`
    padding: 0 0 0;
  `}

  ${media.phone`
    margin-top: -20px;
  `}
`

export const Container = styled.div`
  /* Adds the side margin inherent to the video preview component */
  max-width: calc(1920px + 46px + 46px);
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;

  ${media.tablet`
    flex-flow: column;
    align-items: center;
  `}

  ${media.phone`
    display: block;
  `}
`
