import React from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'

import {
  Section,
  Container,
  TextContainer,
  ImageContainer,
  Img,
  StyledTitle,
  Subtitle,
  Separator,
  Summary,
} from './styled'

const TextAndImageBreaker = ({ title, subtitle, summary, imageUrl }) => (
  <Section>
    <Container>
      <TextContainer>
        <StyledTitle>{title}</StyledTitle>
        <Subtitle>{subtitle}</Subtitle>
        <Separator />
        <Summary dangerouslySetInnerHTML={{ __html: summary }} />
      </TextContainer>
      <ImageContainer>
        <LazyLoad offset={100}>
          <Img src={imageUrl} />
        </LazyLoad>
      </ImageContainer>
    </Container>
  </Section>
)

TextAndImageBreaker.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  summary: PropTypes.string,
  imageUrl: PropTypes.string,
}

export default TextAndImageBreaker
