import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Arrow from '../../assets/icons/arrow_right_red.svg'
import {
  Section,
  Container,
  Title,
  Category,
  SummaryText,
  LinksWrapper,
  LinkWrapper,
  StyledLinkExt,
  Separator,
} from './styled'

class LinksSummary extends Component {
  buildLinksArr() {
    const {
      linkName1,
      linkUrl1,
      linkName2,
      linkUrl2,
      linkName3,
      linkUrl3,
      linkName4,
      linkUrl4,
    } = this.props

    return [
      {
        label: linkName1,
        url: linkUrl1,
      },
      {
        label: linkName2,
        url: linkUrl2,
      },
      {
        label: linkName3,
        url: linkUrl3,
      },
      {
        label: linkName4,
        url: linkUrl4,
      },
    ]
  }

  render() {
    const { title, category, summaryText } = this.props

    const linksArr = this.buildLinksArr()
    return (
      <Section>
        <Container>
          <Category>{category}</Category>
          <Title>{title}</Title>
          <Separator />
          <SummaryText dangerouslySetInnerHTML={{ __html: summaryText }} />
          {linksArr.length > 0 && (
            <LinksWrapper>
              {linksArr.map(item => {
                if (item.label.length === 0 || item.url.length === 0) {
                  return null
                }
                return (
                  <LinkWrapper key={item.label}>
                    <StyledLinkExt
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.label}
                    </StyledLinkExt>
                    <img src={Arrow} />
                  </LinkWrapper>
                )
              })}
            </LinksWrapper>
          )}
        </Container>
      </Section>
    )
  }
}

LinksSummary.defaultProps = {
  title: '',
  category: '',
  summaryText: '',
  linkName1: '',
  linkUrl1: '',
  linkName2: '',
  linkUrl2: '',
  linkName3: '',
  linkUrl3: '',
  linkName4: '',
  linkUrl4: '',
}

LinksSummary.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string,
  summaryText: PropTypes.string,
  linkName1: PropTypes.string,
  linkUrl1: PropTypes.string,
  linkName2: PropTypes.string,
  linkUrl2: PropTypes.string,
  linkName3: PropTypes.string,
  linkUrl3: PropTypes.string,
  linkName4: PropTypes.string,
  linkUrl4: PropTypes.string,
}

export default LinksSummary
