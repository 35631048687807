import styled from 'styled-components'
import media from '../../styles/media'
import { black, white } from '../../styles/colors'

export const OuterFooterContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-top: -55px;
`

export const FooterContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  font-family: 'Gilroy';
  font-weight: 400;
  width: 100%;
  padding: 88px 140px;
  background-color: ${black};
  color: ${white};

  ${media.phone`
    padding: 45px 40px 80px;
    flex-flow: column;
  `}
`

export const InnerFooterContainer = styled.div`
  width: 100%;
  max-width: 1640px;

  ${media.phone`
    display: flex;
    flex-flow: column;
  `}
`

export const OuterGoToTopButtonWrapper = styled.div`
  width: 100%;
  position: relative;
  top: 25px;
  padding-right: 140px;

  ${media.phone`
    padding-right: 40px;
  `}
`

export const GoToTopButtonWrapper = styled.div`
  width: 100%;
  max-width: 1840px;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;

  ${media.phone`
    display: flex;
    flex-flow: column;
  `}
`

export const LeftColWrapper = styled.div`
  max-width: 330px;
`
export const RightColWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 230px;
  position: relative;
  left: 4%;

  ${media.phone`
    left: 0;
  `}
`

export const LogoWrapper = styled.div`
  margin-bottom: 63px;
  width: 100%;
`

export const Header = styled.h2`
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 10px;
  letter-spacing: 1.5px;
  text-transform: uppercase;

  ${media.phone`
    font-size: 12px;
    letter-spacing: 1.8px;
  `}
`

export const CTA = styled.p`
  font-weight: 300;
  margin-bottom: 20px;
  letter-spacing: 0.16px;
`

export const ButtonWrapper = styled.div`
  margin: 5px 0;
`

export const SocialIconsWrapper = styled.div`
  ${media.phone`
    margin-bottom: 60px;
  `}
`

export const NavItemsWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  height: 120px;

  ${media.phone`
    margin-bottom: 40px;
  `}
`

export const PrivacyItemsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  max-width: 150px;

  ${media.tablet`
    max-width: 175px;
  `}

  ${media.phone`
    max-width: 175px;
  `}
`
