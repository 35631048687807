import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/Layout'
import media from '../styles/media'
import SummaryGrid from '../components/Work/SummaryGrid'
import DetailPreviewCard from '../components/Work/DetailPreviewCard'
import BreakerPreviewCard from '../components/BreakerPreviewCard'
import { links } from '../styles/links'
import Title from '../components/Title'

const StyledTitle = styled(Title)`
  padding: 100px 48px 80px;

  ${media.tablet`
    padding: 45px 0 42px;
  `}

  ${media.phone`
    padding: 45px 0 18px;
  `}
`

const TramLines = styled.div`
  width: 100%;
  padding: 0 91px;

  ${media.tablet`
    padding: 0 86px;
  `}

  ${media.phone`
    padding: 0 36px;
  `}
`

class WorkPage extends Component {
  render() {
    const gridItems = this.props.data.kenticoCloudItemWorkSummaryPage.elements
      .grid_items
    const {
      seo_metadata_example_to_include_in_any_type__meta_description,
      seo_metadata_example_to_include_in_any_type__meta_title,
      seo_metadata_example_to_include_in_any_type__opengraph_image,
    } = this.props.data.kenticoCloudItemWorkSummaryPage.elements
    return (
      <Layout
        location={links.work}
        metaDesc={
          seo_metadata_example_to_include_in_any_type__meta_description.value
        }
        metaTitle={
          seo_metadata_example_to_include_in_any_type__meta_title.value
        }
        metaImg={
          seo_metadata_example_to_include_in_any_type__opengraph_image.value[0]
            .url
        }
      >
        <TramLines>
          <StyledTitle>Latest work</StyledTitle>
          <SummaryGrid>
            {gridItems.map(item => {
              if (item.internal.type === 'KenticoCloudItemWorkDetailPage') {
                return (
                  <DetailPreviewCard
                    key={item.id}
                    to={`/work/${item.elements.slug.value}`}
                    image={
                      item.elements.preview_image.value.length > 0 &&
                      item.elements.preview_image.value[0].url
                    }
                    client={item.elements.client_name.value}
                    project={item.elements.project_name.value}
                    tagline={item.elements.intro_text.value}
                    tags={item.elements.services.value}
                    isFullWidth={
                      item.elements.preview_card_size.value[0] &&
                      item.elements.preview_card_size.value[0].codename ===
                        'large'
                    }
                  />
                )
              }
              if (item.internal.type === 'KenticoCloudItemBreakerPreviewCard') {
                return (
                  <BreakerPreviewCard
                    key={item.id}
                    text={item.elements.text.value}
                    to={item.elements.button_target.value}
                    btnText={item.elements.button_label.value}
                  />
                )
              }
            })}
          </SummaryGrid>
        </TramLines>
      </Layout>
    )
  }
}

WorkPage.propTypes = {
  data: PropTypes.object,
}

export default WorkPage

export const workPageQuery = graphql`
  {
    kenticoCloudItemWorkSummaryPage(elements: {}) {
      elements {
        seo_metadata_example_to_include_in_any_type__meta_description {
          value
        }
        seo_metadata_example_to_include_in_any_type__meta_title {
          value
        }
        seo_metadata_example_to_include_in_any_type__opengraph_image {
          value {
            url
          }
        }
        grid_items {
          ... on KenticoCloudItemBreakerPreviewCard {
            elements {
              text {
                value
              }
              button_target {
                value
              }
              button_label {
                value
              }
            }
            internal {
              type
            }
            id
          }
          ... on KenticoCloudItemWorkDetailPage {
            elements {
              project_name {
                value
              }
              preview_card_size {
                value {
                  codename
                }
              }
              preview_image {
                value {
                  url
                }
              }
              intro_text {
                value
              }
              services {
                value
              }
              slug {
                value
              }
              client_name {
                value
              }
              hero_title {
                value
              }
            }
            internal {
              type
            }
            id
          }
        }
      }
    }
  }
`
