import styled from 'styled-components'
import { black } from '../../styles/colors'
import media from '../../styles/media'

export const Section = styled.section`
  width: 100%;
  max-width: 1920px;
  font-family: 'Gilroy';
  padding: 120px 36px 80px;

  ${media.phone`
    padding: 30px 36px 50px;
  `}
`

export const Container = styled.div`
  width: 100%;
  max-width: 862px;
  margin: 0 auto;
`

export const HeaderContainer = styled.div`
  position: relative;
  cursor: pointer;
`

export const AccItem = styled.div`
  border-bottom: 1px solid ${black};
  margin: 0 auto;
  margin-bottom: 25px;
  padding-bottom: ${({ isOpen }) => isOpen && '30px'};
`

export const TogglerWrapper = styled.div`
  position: relative;
`

export const Toggler = styled.img`
  width: 42px;
  opacity: ${({ opacity }) => opacity};
  transition: all ease-in-out 0.2s;
  position: absolute;
  top: ${({ isMinus }) => (isMinus ? '19px' : '0')};
  left: 0;
  transform: ${({ rotate }) => `rotate(${rotate}deg)`};

  ${media.phone`
    width: 25px;
    top: ${({ isMinus }) => (isMinus ? '15px' : '4px')};
  `}
`

export const AccHeaderWrapper = styled.div`
  margin-left: 90px;

  ${media.phone`
    margin-left: 55px;
  `}
`

export const AccHeader = styled.div`
  color: ${black};
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -0.4px;
  line-height: 35px;
  margin-bottom: 25px;

  ${media.phone`
    font-size: 20px;
    letter-spacing: -0.27px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 15px;
  `}
`

export const AccSubheader = styled.div`
  color: ${black};
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 25px;
  max-width: 721px;

  ${media.phone`
    line-height: 30px;
  `}
`

export const AccContent = styled.div`
  color: ${black};
  overflow: hidden;
  max-height: ${({ maxHeight }) => maxHeight};
  transition: all;
  transition-duration: 0.5s;
  transition-delay: 0;
  transition-timing-function: ease-in-out;

  & p {
    max-width: 721px;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    margin-left: 90px;
    margin-bottom: 30px;

    ${media.phone`
      line-height: 30px;
      margin-left: 55px;
    `}
  }

  & h2 {
    max-width: 829px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.32px;
    line-height: 35px;
    margin-bottom: 45px;
    margin-top: 45px;

    ${media.phone`
      max-width: 721px;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 30px;
      line-height: 30px;
      margin-left: 55px;
    `}
  }

  & h3 {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-transform: uppercase;
    margin-left: 90px;
  }

  & h4 {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2.1px;
    line-height: 41px;
    text-transform: uppercase;
    margin: 30px 0 14px 90px;

    ${media.phone`
      line-height: 30px;
      margin-left: 55px;
    `}
  }

  .tct-container {
    font-family: 'Gilroy';
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    max-width: 719px;
    margin: 0 0 0 114px;

    ${media.phone`
      margin: 0 0 0 79px;
      flex-flow: column;
    `}
  }
`
