import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from './styled'

const SocialLink = ({ to, logo }) => {
  return (
    <Wrapper href={to} target="_blank" rel="noopener noreferrer">
      <img src={logo} />
    </Wrapper>
  )
}

SocialLink.propTypes = {
  to: PropTypes.string,
  logo: PropTypes.string,
}

export default SocialLink
