export const links = {
  home: '/',
  about: '/about',
  work: '/work',
  people: '/people',
  showreel: '/showreel',
  blog: '/blog',
  contact: '/contact',
  impressum: '/impressum',
  datenschutz: '/datenschutzerklarung',
  imprint: '/imprint',
  dataProtection: '/data-protection',
}

export const linkTypes = {
  headerLink: 'headerLink',
  footerLink: 'footerLink',
  privacyLink: 'privacyLink',
}

export const linksArr = Object.values(links)
