import React from 'react'
import Layout from '../components/Layout'
import Title from '../components/Title'
import BodyText from '../components/BodyText'
import styled from 'styled-components'
import media from '../styles/media'

const StyledTitle = styled(Title)`
  padding: 100px 48px 32px;

  ${media.tablet`
    padding: 45px 24px 27px;
  `}

  ${media.phone`
    padding: 45px 36px 27px;
  `}
`

const TramLines = styled.div`
  width: 100%;
  padding: 0 91px;

  ${media.tablet`
    padding: 0 62px;
  `}

  ${media.phone`
    padding: 0 0;
  `}
`

const htmlCopy =
  '<p>The page you are looking for cannot be found. Please check the Url and try again.</p>'

const NotFoundPage = () => (
  <Layout>
    <TramLines>
      <StyledTitle>Page not found</StyledTitle>
      <BodyText text={htmlCopy} />
    </TramLines>
  </Layout>
)

export default NotFoundPage
