import styled, { css } from 'styled-components'
import media from '../../styles/media'
import { red } from '../../styles/colors'

export const Container = styled.div`
  display: flex;
  width: 50%;
  padding: 0 50px;

  ${media.tablet`
    width: 100%;
    padding: 0 50px;
  `}

  ${media.phone`
    padding: 0;
  `}

  ${({ isBlogItem }) => {
    if (isBlogItem) {
      return css`
        position: relative;
        width: 27.7%;
        padding: 25.8% 48px 0;
        margin: 2.8%;

        ${media.desktop`
          width: 41.97%;
          padding: 38.936% 48px 0;
          margin: 4%;
        `}

        ${media.tablet`
          width: 45.344%;
          padding: 42.058% 15px 0;
          margin: 2.2%;
        `}

        ${media.phone`
          position: static;
          width: 100%;
          padding: 0;
          margin: 62px 18px;
        `}
      `
    }
  }}
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  padding: 0 48px;
  height: 608px;

  ${media.tablet`
    width: 100%;
    max-width: 100%;
    margin-bottom: 50px;
    height: auto;
  `}

  ${media.phone`
    padding: 0;
  `}

  @media screen and (max-width: 1699px) and (min-width: 1500px) {
    height: 687px;
  }

  @media screen and (max-width: 1919px) and (min-width: 1700px) {
    height: 810px;
  }

  ${media.desktopXl`
    height: 945px;
  `}

  ${({ isBlogItem }) => {
    if (isBlogItem) {
      return css`
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 0 0;
        width: 100%;
        max-width: 100%;
        height: 100%;

        ${media.tablet`
          margin-bottom: 0;
        `}

        ${media.phone`
          position: static;
        `}

        @media screen and (max-width: 1699px) and (min-width: 1500px) {
          height: 100%;
        }

        @media screen and (max-width: 1919px) and (min-width: 1700px) {
          height: 100%;
        }

        ${media.desktopXl`
          height: 100%;
        `}
      `
    }
  }}
`

export const Text = styled.div`
  font-family: 'Gilroy';
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  width: 396px;
  margin-bottom: 30px;

  ${media.phone`
    font-size: 20px;
    line-height: 30px;
    width: 100%;
    margin-bottom: 36px;
  `}

  ${({ isBlogItem }) => {
    if (isBlogItem) {
      return css`
        width: 81.07%;

        ${media.tablet`
          font-size: 20px;
          line-height: 35px;
          width: 81.07%;
          max-width: 396px;
        `}

        ${media.phone`
          width: 100%;
        `}
      `
    }
  }}
`

export const Line = styled.div`
  width: 42px;
  border-top: 1px solid ${red};
  margin-bottom: ${({ isTo }) => isTo && '50px'};
`
