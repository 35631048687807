import React from 'react'
import PropTypes from 'prop-types'
import Carousel from './Carousel'

class TestimonialCarousel extends React.Component {
  state = {
    counter: 1,
    jobTitle: null,
    name: null,
    isGoNext: false,
  }

  componentDidMount() {
    const { data } = this.props
    this.setState({
      name: data[0].name,
      jobTitle: data[0].jobTitle,
    })
  }

  goNextParent = () => {
    const { data } = this.props
    const { counter } = this.state
    const totalSlides = data.length
    if (counter !== totalSlides) {
      const num = counter + 1
      this.setState({
        counter: num,
        jobTitle: data[num - 1].jobTitle,
        name: data[num - 1].name,
      })
    }
  }

  goPrevParent = () => {
    const { data } = this.props
    const { counter } = this.state
    if (counter !== 1) {
      const num = counter - 1
      this.setState({
        counter: num,
        jobTitle: data[num - 1].jobTitle,
        name: data[num - 1].name,
      })
    }
  }

  render() {
    const { data, isHome, isShowreel } = this.props
    const { counter, jobTitle, name } = this.state
    return (
      <Carousel
        counter={counter}
        jobTitle={jobTitle}
        name={name}
        goPrevParent={this.goPrevParent}
        goNextParent={this.goNextParent}
        data={data}
        isHome={isHome}
        isShowreel={isShowreel}
      />
    )
  }
}

TestimonialCarousel.propTypes = {
  data: PropTypes.array,
  isHome: PropTypes.bool,
  isShowreel: PropTypes.bool,
}

export default TestimonialCarousel
