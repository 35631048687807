import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'

import Layout from 'components/Layout'
import LogoGroup from 'components/LogoGroup'
import TestimonialCarousel from 'components/Testimonial/CarouselParent'
import WorkCarousel from 'components/WorkCarousel/Carousel'
import BodyText from 'components/BodyText'
import HeroImage from 'components/HeroImage'
import HeroVideo from 'components/HeroVideo'
import ServicesCarousel from 'components/ServicesCarousel'

import getTestimonialDetails from '../utils/testimonials/getTestimonialDetails'

import { links } from '../styles/links'

const IndexPage = ({ data }) => {
  const {
    client_logos,
    testimonials,
    body_text,
    work_carousel,
    text_overlay,
    image_hero,
    video_hero,
    hero_choice,
    services_carousel,
    seo_metadata_example_to_include_in_any_type__meta_description,
    seo_metadata_example_to_include_in_any_type__meta_title,
    seo_metadata_example_to_include_in_any_type__opengraph_image,
  } = data.kenticoCloudItemHomePage.elements
  const allLogos = client_logos[0].elements.logos
  const allTestimonials = testimonials[0].elements.testimonial_group

  let servicesArr = []
  services_carousel.forEach(item => {
    const service = {
      title: item.elements.homepage_title.value,
      anchor: item.elements.anchor.value,
      imageUrl: item.elements.homepage_image.value[0].url,
    }
    servicesArr.push(service)
  })

  let logosArr = []
  allLogos.forEach(item => {
    if (!item.elements) return

    const logo = {
      url: item.elements.image.assets[0].url,
      alt:
        item.elements.image.assets.length > 0
          ? item.elements.image.assets[0].description
          : '',
    }
    logosArr.push(logo)
  })

  let testimonialsArr = []
  allTestimonials.forEach(item => {
    const testimonial = getTestimonialDetails(item)
    if (testimonial) testimonialsArr.push(testimonial)
  })

  let projectArr = []
  work_carousel.forEach(item => {
    const project = {
      introText: item.elements.intro_text.value,
      slug: item.elements.slug.value,
      heroTitle: item.elements.hero_title.value,
      imageUrl: item.elements.hero_image.value[0].url,
    }
    projectArr.push(project)
  })
  const isHeroImage = hero_choice.value[0].codename.includes('image')
  const url = image_hero.value[0] ? image_hero.value[0].url : null

  return (
    <Layout
      location={links.home}
      metaDesc={
        seo_metadata_example_to_include_in_any_type__meta_description.value
      }
      metaTitle={seo_metadata_example_to_include_in_any_type__meta_title.value}
      metaImg={
        seo_metadata_example_to_include_in_any_type__opengraph_image.value[0]
          .url
      }
      isHeroImage={isHeroImage}
    >
      {isHeroImage ? (
        <HeroImage text={text_overlay.value} imageUrl={url} />
      ) : (
        <HeroVideo text={text_overlay.value} videoId={video_hero.value} />
      )}
      <WorkCarousel data={projectArr} />
      <div style={{ marginTop: '20px' }}>
        <BodyText text={body_text.value} isHomePage />
      </div>
      <LazyLoad offset={100}>
        <ServicesCarousel services={servicesArr} />
      </LazyLoad>
      <TestimonialCarousel data={testimonialsArr} isHome />
      <div style={{ marginBottom: '-50px' }}>
        <LazyLoad offset={100}>
          <LogoGroup logos={logosArr} />
        </LazyLoad>
      </div>
    </Layout>
  )
}

IndexPage.defaultProps = {
  data: {},
}

IndexPage.propTypes = {
  data: PropTypes.object,
}

export default IndexPage

export const sampleQuery = graphql`
  {
    kenticoCloudItemHomePage {
      elements {
        seo_metadata_example_to_include_in_any_type__meta_description {
          value
        }
        seo_metadata_example_to_include_in_any_type__meta_title {
          value
        }
        seo_metadata_example_to_include_in_any_type__opengraph_image {
          value {
            url
          }
        }
        client_logos {
          elements {
            ... on KenticoCloudItemLogoGroupElements {
              logos {
                ... on KenticoCloudItemLogo {
                  elements {
                    image {
                      assets {
                        url
                        description
                      }
                    }
                  }
                }
              }
            }
          }
        }
        work_carousel {
          elements {
            intro_text {
              value
            }
            slug {
              value
            }
            hero_title {
              value
            }
            hero_image {
              value {
                url
              }
            }
          }
        }
        testimonials {
          elements {
            ... on KenticoCloudItemTestimonialGroupElements {
              testimonial_group {
                ... on KenticoCloudItemTestimonial {
                  elements {
                    job_title {
                      value
                    }
                    name {
                      value
                    }
                    quote {
                      value
                    }
                  }
                  id
                }
              }
            }
          }
        }
        services_carousel {
          elements {
            anchor {
              value
            }
            homepage_image {
              value {
                url
              }
            }
            homepage_title {
              value
            }
          }
        }
        body_text {
          value
        }
        text_overlay {
          value
        }
        image_hero {
          value {
            url
          }
        }
        video_hero {
          value
        }
        hero_choice {
          value {
            codename
          }
        }
      }
    }
  }
`
