import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from './styled'
import Arrow from '../../assets/icons/arrow_up.svg'

const GoToTopButton = ({ handleScrollToTop }) => {
  return (
    <Wrapper onClick={() => handleScrollToTop()}>
      <img src={Arrow} />
    </Wrapper>
  )
}

GoToTopButton.propTypes = {
  handleScrollToTop: PropTypes.func,
}

export default GoToTopButton
