import styled from 'styled-components'
import media from '../../styles/media'
import { red } from '../../styles/colors'

export const Wrapper = styled.div`
  margin: 113px 0 90px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.phone`
    width: 100%;
    padding: 0 36px;
    margin: 72px 0 50px 0;
  `}
`

export const Text = styled.p`
  max-width: 700px;
  font-family: 'Gilroy';
  font-size: 48px;
  font-weight: 600;
  line-height: 54px;
  color: ${red};
  margin-bottom: 54px;
  text-align: center;

  ${media.phone`
    font-size: 30px;
    letter-spacing: -0.3px;
    line-height: 35px;
    margin-bottom: 0;
  `}
`

export const Line = styled.div`
  width: 42px;
  border-top: 1px solid ${red};

  ${media.phone`
    display: none;
  `}
`
