import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Section,
  Container,
  Label,
  Copy,
  Wrapper,
  Value,
  Stats,
  Stat,
  StatLabel,
} from './styled'

class StatsBreaker extends Component {
  buildStats() {
    const {
      statLabel1,
      statValue1,
      statLabel2,
      statValue2,
      statLabel3,
      statValue3,
    } = this.props

    return [
      {
        label: statLabel1,
        value: statValue1,
      },
      {
        label: statLabel2,
        value: statValue2,
      },
      {
        label: statLabel3,
        value: statValue3,
      },
    ]
  }

  render() {
    const { title, summary } = this.props

    const stats = this.buildStats()
    return (
      <Section>
        <Container>
          <Label>{title}</Label>
          <Wrapper>
            <Copy dangerouslySetInnerHTML={{ __html: summary }} />
            <Stats>
              {stats &&
                stats.map((stat, i) => {
                  if (stat.value === '' || stat.label === '') {
                    return null
                  }
                  return (
                    <Stat key={i}>
                      <Value>{stat.value}</Value>
                      <StatLabel>{stat.label}</StatLabel>
                    </Stat>
                  )
                })}
            </Stats>
          </Wrapper>
        </Container>
      </Section>
    )
  }
}

StatsBreaker.propTypes = {
  title: PropTypes.string,
  summary: PropTypes.string,
  statLabel1: PropTypes.string,
  statValue1: PropTypes.string,
  statLabel2: PropTypes.string,
  statValue2: PropTypes.string,
  statLabel3: PropTypes.string,
  statValue3: PropTypes.string,
}

export default StatsBreaker
