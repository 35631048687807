import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from 'components/Layout'
import Title from '../components/Title'
import BodyText from '../components/BodyText'
import media from '../styles/media'

const StyledTitle = styled(Title)`
  padding: 100px 48px 32px;
  word-wrap: break-word;

  ${media.tablet`
    padding: 45px 24px 27px;
  `}

  ${media.phone`
    padding: 45px 36px 27px;
  `}
`

const TramLines = styled.div`
  width: 100%;
  padding: 0 91px;

  ${media.tablet`
    padding: 0 62px;
  `}

  ${media.phone`
    padding: 0 0;
  `}
`

const GeneralArticlePage = ({ data }) => {
  const {
    title,
    body_text,
    seo_metadata_example_to_include_in_any_type__meta_description,
    seo_metadata_example_to_include_in_any_type__meta_title,
    seo_metadata_example_to_include_in_any_type__opengraph_image,
  } = data.allKenticoCloudItemGeneralArticle.edges[0].node.elements

  return (
    <Layout
      metaDesc={
        seo_metadata_example_to_include_in_any_type__meta_description.value
      }
      metaTitle={seo_metadata_example_to_include_in_any_type__meta_title.value}
      metaImg={
        seo_metadata_example_to_include_in_any_type__opengraph_image.value[0]
          .url
      }
    >
      <TramLines>
        <StyledTitle>{title.value}</StyledTitle>
        <BodyText isGeneralArticle text={body_text.resolvedHtml} />
      </TramLines>
    </Layout>
  )
}

GeneralArticlePage.propTypes = {
  data: PropTypes.object,
}

export default GeneralArticlePage

export const query = graphql`
  query generalArticleQuery($slug: String!) {
    allKenticoCloudItemGeneralArticle(
      filter: { elements: { slug: { value: { eq: $slug } } } }
    ) {
      edges {
        node {
          elements {
            seo_metadata_example_to_include_in_any_type__meta_description {
              value
            }
            seo_metadata_example_to_include_in_any_type__meta_title {
              value
            }
            seo_metadata_example_to_include_in_any_type__opengraph_image {
              value {
                url
              }
            }
            slug {
              value
            }
            body_text {
              resolvedHtml
            }
            title {
              value
            }
          }
        }
      }
    }
  }
`
