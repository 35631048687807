import styled, { css } from 'styled-components'
import media from '../../styles/media'
import { black, red, grey } from '../../styles/colors'

export const Section = styled.section`
  display: flex;
  justify-content: center;
  padding: ${({ isAboutPage }) => (isAboutPage ? '85px 0 0 0' : '85px 50px')};

  ${media.tablet`
    padding: ${({ isAboutPage }) => (isAboutPage ? '45px 0 0 0' : '45px 0')};
  `}
`

export const TextWrapper = styled.div`
  max-width: ${({ isHomePage }) => (isHomePage ? '856px' : '784px')};

  ${media.tablet`
    padding: 0 36px;
  `}

  ${media.phone`
    max-width: 400px;
  `}

  ${({ isGeneralArticle }) =>
    isGeneralArticle &&
    css`
      overflow: hidden;

      ${media.tablet`
        padding: 0 24px;
      `}

      ${media.phone`
        padding: 0 36px;
        max-width: none;
      `}
    `}
`

export const Text = styled.div`
  font-family: 'Gilroy';
  color: ${black};

  & h1 {
    margin: 16px auto 54px;
    color: ${red};
    font-size: 48px;
    font-weight: 600;
    letter-spacing: -0.48px;
    line-height: 54px;
    text-align: center;

    ${media.phone`
      font-size: 30px;
      letter-spacing: -0.3px;
      line-height: 35px;
    `}

    ${({ isAboutPage }) =>
      isAboutPage &&
      css`
        font-size: 48px;
        text-align: center;
        max-width: 553px;

        ${media.phone`
          width: 88%;
          margin-bottom: 25px;
        `}
      `}
  }

  & h2 {
    margin: 8px 0 44px;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;

    ${media.phone`
      font-size: 20px;
      line-height: 30px;
    `}

    ${({ isAboutPage }) =>
      isAboutPage &&
      css`
        text-align: center;
        max-width: 720px;
        margin: 8px auto 0;

        &::after {
          content: '';
          width: 41px;
          height: 1px;
          background-color: ${red};
          display: block;
          margin: 32px auto -10px;
        }
      `}
  }

  & h3 {
    margin: 8px 0 28px;
    line-height: 28px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    ${media.phone`
      font-size: 18px;
      line-height: 28px;
    `}
  }

  & h4 {
    color: ${grey};
    margin: 8px 0 28px;
    line-height: 28px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    ${media.phone`
      font-size: 18px;
      line-height: 28px;
    `}
  }

  & p {
    margin: 0 0 20px;
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;

    ${media.phone`
      line-height: 30px;
    `}

    ${({ isAboutPage }) =>
      isAboutPage &&
      css`
        text-align: center;
      `}

    ${({ isHomePage }) =>
      isHomePage &&
      css`
        font-size: 22px;
        text-align: center;
        line-height: 32px;
        font-weight: 600;
        color: ${black};
        margin-bottom: 20px;

        ${media.phone`
          font-size: 18px;
          line-height: 28px;
        `}
      `}
  }

  & strong {
    color: ${red};
  }

  & em {
    font-style: italic;
  }
`
