import { css } from 'styled-components'

import GilroyLightEot from '../assets/fonts/Gilroy-light/gilroy-light.eot'
import GilroyLightWoff from '../assets/fonts/Gilroy-light/gilroy-light.woff'
import GilroyLightWoff2 from '../assets/fonts/Gilroy-light/gilroy-light.woff2'
import GilroyLightTtf from '../assets/fonts/Gilroy-light/gilroy-light.ttf'

import GilroyRegularEot from '../assets/fonts/Gilroy-regular/gilroy-regular.eot'
import GilroyRegularWoff from '../assets/fonts/Gilroy-regular/gilroy-regular.woff'
import GilroyRegularWoff2 from '../assets/fonts/Gilroy-regular/gilroy-regular.woff2'
import GilroyRegularTtf from '../assets/fonts/Gilroy-regular/gilroy-regular.ttf'

import GilroySemiBoldEot from '../assets/fonts/Gilroy-semibold/gilroy-semibold.eot'
import GilroySemiBoldWoff from '../assets/fonts/Gilroy-semibold/gilroy-semibold.woff'
import GilroySemiBoldWoff2 from '../assets/fonts/Gilroy-semibold/gilroy-semibold.woff2'
import GilroySemiBoldTtf from '../assets/fonts/Gilroy-semibold/gilroy-semibold.ttf'

import GilroyBoldEot from '../assets/fonts/Gilroy-bold/gilroy-bold.eot'
import GilroyBoldWoff from '../assets/fonts/Gilroy-bold/gilroy-bold.woff'
import GilroyBoldWoff2 from '../assets/fonts/Gilroy-bold/gilroy-bold.woff2'
import GilroyBoldTtf from '../assets/fonts/Gilroy-bold/gilroy-bold.ttf'

const fonts = css`
  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    src: url(${GilroyLightEot});
    src: url(${GilroyLightWoff2}) format('woff2'),
      url(${GilroyLightWoff}) format('woff'),
      url(${GilroyLightTtf}) format('truetype');
  }
  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    src: url(${GilroyRegularEot});
    src: url(${GilroyRegularWoff2}) format('woff2'),
      url(${GilroyRegularWoff}) format('woff'),
      url(${GilroyRegularTtf}) format('truetype');
  }
  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    src: url(${GilroySemiBoldEot});
    src: url(${GilroySemiBoldWoff2}) format('woff2'),
      url(${GilroySemiBoldWoff}) format('woff'),
      url(${GilroySemiBoldTtf}) format('truetype');
  }
  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    src: url(${GilroyBoldEot});
    src: url(${GilroyBoldWoff2}) format('woff2'),
      url(${GilroyBoldWoff}) format('woff'),
      url(${GilroyBoldTtf}) format('truetype');
  }
`

export { fonts }
