import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import { Wrapper } from './styled'

class GatsbyLink extends React.Component {
  handleCloseMenu = () => {
    const { label, closeMenu } = this.props
    if (closeMenu) {
      closeMenu(label)
    }
  }

  render() {
    const {
      to,
      linkType,
      label,
      color,
      fSize,
      display,
      forSb,
      location,
    } = this.props

    return (
      <>
        {forSb ? (
          <Wrapper
            linkType={linkType}
            onClick={this.handleCloseMenu}
            color={color}
            fSize={fSize}
            display={display}
            location={location}
            to={to}
            label={label}
          >
            {label}
          </Wrapper>
        ) : (
          <Link to={to}>
            <Wrapper
              linkType={linkType}
              onClick={this.handleCloseMenu}
              color={color}
              fSize={fSize}
              display={display}
              location={location}
              to={to}
              label={label}
            >
              {label}
            </Wrapper>
          </Link>
        )}
      </>
    )
  }
}

GatsbyLink.propTypes = {
  linkType: PropTypes.string,
  to: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  fSize: PropTypes.string,
  display: PropTypes.string,
  closeMenu: PropTypes.func,
  location: PropTypes.string,
  forSb: PropTypes.bool,
}

export default GatsbyLink
