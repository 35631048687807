import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Image, Scrim, OverlayText } from './styled'
import { allEmptyTextValues } from '../../styles/const'

const HeroImage = ({ text, imageUrl }) => {
  const isTextEmpty = allEmptyTextValues.some(value => text === value)
  return (
    <Wrapper>
      {!isTextEmpty && (
        <>
          <Scrim />
          <OverlayText dangerouslySetInnerHTML={{ __html: text }} />
        </>
      )}
      <Image style={{ backgroundImage: `url("${imageUrl}")` }} />
    </Wrapper>
  )
}

HeroImage.propTypes = {
  text: PropTypes.string,
  imageUrl: PropTypes.string,
}

export default HeroImage
