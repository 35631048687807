const primary = '#AFDCD8'
const white = '#FFF'
const black = '#000'
const red = '#e8423b'
const grey = '#a3a3a3'
const darkGrey = '#505050'
const lightGrey = '#f2f1f1'
const transparent = 'rgba(0, 0, 0, 0)'

export { primary, white, black, red, grey, darkGrey, lightGrey, transparent }
