import React from 'react'
import PropTypes from 'prop-types'
import { Section, Container } from './styled'

const VideoPreviewGrid = ({ children }) => {
  return (
    <Section>
      <Container>{children}</Container>
    </Section>
  )
}

VideoPreviewGrid.propTypes = {
  children: PropTypes.node.isRequired,
}

export default VideoPreviewGrid
