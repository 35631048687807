import React from 'react'
import PropTypes from 'prop-types'
import toPx from 'to-px'
import breakpoints from '../styles/breakpoints'

export const BREAKPOINTS = {
  phone: 'phone',
  tablet: 'tablet',
  desktop: 'desktop',
  desktopXl: 'desktopXl',
}

function withCurrentBreakpoint(Component) {
  class sizedComponent extends React.Component {
    state = {
      width: null,
    }

    componentDidMount() {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions)
    }

    updateWindowDimensions = () => {
      this.setState({ width: window.innerWidth })
    }

    render() {
      const { width } = this.state
      const tabletBreakpoint = toPx(breakpoints.tablet)
      const desktopBreakpoint = toPx(breakpoints.desktop)
      const largeDesktopBreakpoint = toPx(breakpoints.desktopXl)

      let currentBreakpoint
      if (width > 0 && width <= tabletBreakpoint) {
        currentBreakpoint = BREAKPOINTS.phone
      } else if (width > tabletBreakpoint && width <= desktopBreakpoint) {
        currentBreakpoint = BREAKPOINTS.tablet
      } else if (width > desktopBreakpoint && width <= largeDesktopBreakpoint) {
        currentBreakpoint = BREAKPOINTS.desktop
      } else if (width > largeDesktopBreakpoint) {
        currentBreakpoint = BREAKPOINTS.desktopXl
      }

      return <Component currentBreakpoint={currentBreakpoint} {...this.props} />
    }
  }

  sizedComponent.propTypes = {
    size: PropTypes.object,
  }

  return sizedComponent
}

export default withCurrentBreakpoint
