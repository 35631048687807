import get from 'lodash/get'

const getTestimonialDetails = item => {
  const testimonial = {
    jobTitle: get(item, 'elements.job_title.value', ''),
    name: get(item, 'elements.name.value', ''),
    quote: get(item, 'elements.quote.value', ''),
    id: item.id,
  }

  if (!testimonial.jobTitle || !testimonial.name || !testimonial.quote)
    return null

  return testimonial
}

export default getTestimonialDetails
