import styled from 'styled-components'
import media from '../../styles/media'
import { red } from '../../styles/colors'

export const Section = styled.section`
  display: flex;
  justify-content: center;
  margin-top: 100px;

  ${media.phone`
    margin-top: 36px;
  `}
`

export const Container = styled.div`
  font-family: 'Gilroy';
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  max-width: 784px;

  ${media.tablet`
    padding: 0 36px;
  `}

  ${media.phone`
    flex-flow: column;
  `}
`

export const Column = styled.div`
  width: 39%;

  & > ul {
    list-style: inside;
  }

  & > ul > li {
    line-height: 24px;
    text-indent: -24px;
    margin-bottom: 18px;
    font-size: 18px;
    font-weight: 400;
  }

  & > h1 {
    margin: 16px auto 54px;
    color: #e8423b;
    font-size: 48px;
    font-weight: 600;
    letter-spacing: -0.48px;
    line-height: 54px;
  }

  & > h2 {
    margin: 8px 0 44px;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
  }

  & > h3 {
    margin: 8px 0 28px;
    line-height: 28px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.32px;
    text-transform: uppercase;
  }

  & > h4 {
    color: #a3a3a3;
    margin: 8px 0 28px;
    line-height: 28px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.32px;
    text-transform: uppercase;
  }

  & > p {
    margin: 0 0 20px;
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
  }

  & > strong {
    color: ${red};
  }

  ${media.phone`
    width: 100%;

    & > h1 {
      font-size: 30px;
      letter-spacing: -0.3px;
      line-height: 35px;
    }
  
    & > h2 {
      font-size: 20px;
      line-height: 30px;
    }
  
    & > h3 {
      font-size: 18px;
      line-height: 28px;
    }
  
    & > h4 {
      font-size: 18px;
      line-height: 28px;
    }
  
    & > p {
      margin: 0 0 20px;
      font-size: 18px;
      line-height: 32px;
      font-weight: 400;
    }
  `}
`
