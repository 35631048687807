import styled from 'styled-components'
import { black, white, red, lightGrey } from '../../styles/colors'
import media from '../../styles/media'
import Title from '../Title'

export const Section = styled.section`
  max-width: 1920px;
  background-color: ${lightGrey};
  padding: 140px 36px;
  font-family: 'Gilroy';

  ${media.phone`
    padding: 65px 0 35px;
    background-color: ${white};
  `}
`

export const Container = styled.div`
  max-width: 1006px;
  margin: 0 auto;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding-left: 160px;

  ${media.tablet`
    max-width: 784px;
    padding-left: 0px;

  `}

  ${media.phone`
    padding-left: 0;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    left: 0;
  `}
`

export const TextContainer = styled.div`
  width: 43.9%;

  ${media.tablet`
    width: 50%;
  `}

  ${media.phone`
    width: 100%;
    max-width: 400px;
    padding: 0 36px;
    margin: 0 auto;
  `}
`

export const ImageContainer = styled.div`
  width: 44%;
  display: flex;
  align-items: center;

  ${media.phone`
    width: 100%;
    padding-bottom: 66.67%;
    position: relative;
  `}
`

export const Img = styled.img`
  width: 100%;

  ${media.phone`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
  `}
`

export const StyledTitle = styled(Title)`
  margin-bottom: 12px;
`

export const Subtitle = styled.div`
  color: ${black};
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 45px;

  ${media.phone`
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;
  `}
`

export const Separator = styled.div`
  width: 42px;
  height: 1px;
  background-color: ${red};
  margin-bottom: 50px;

  ${media.phone`
    margin-bottom: 35px;
  `}
`

export const Summary = styled.div`
  color: ${black};
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  white-space: pre-line;

  ${media.phone`
    line-height: 30px;
    margin-bottom: 70px;
  `}
`
