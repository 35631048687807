import styled from 'styled-components'
import { red } from '../../styles/colors'
import media from '../../styles/media'

const Title = styled.h1`
  font-family: 'Gilroy';
  font-size: 48px;
  font-weight: 600;
  line-height: 54px;
  letter-spacing: -0.5px;
  color: ${red};

  ${media.phone`
    font-size: 30px;
    line-height: 35px;
    letter-spacing: -0.3px;
  `}
`

export default Title
