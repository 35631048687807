import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import {
  LinkWrapper,
  Container,
  Wrapper,
  Scrim,
  Tag,
  PostTitle,
  Tagline,
} from './styled'

const BlogPreviewCard = ({ tags, postTitle, tagline, imageUrl, to }) => {
  let tagsArr = []
  for (let i = 0; i < tags.length; i++) {
    tagsArr.push(tags[i].name)
  }

  return (
    <Container>
      <LinkWrapper>
        <Link to={to}>
          <Wrapper style={{ backgroundImage: `url("${imageUrl}")` }}>
            <Scrim />
            <Tag>{tagsArr.join(', ')}</Tag>
            <PostTitle>{postTitle}</PostTitle>
            <Tagline>{tagline}</Tagline>
          </Wrapper>
        </Link>
      </LinkWrapper>
    </Container>
  )
}

BlogPreviewCard.propTypes = {
  tag: PropTypes.string,
  postTitle: PropTypes.string,
  tagline: PropTypes.string,
  imageUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  to: PropTypes.string,
  tags: PropTypes.array,
}

export default BlogPreviewCard
