import styled from 'styled-components'
import { red, white } from '../../styles/colors'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: ${red};
  color: ${white};
  cursor: pointer;
`
