import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import LazyLoad from 'react-lazyload'
import styled from 'styled-components'
import Layout from 'components/Layout'
import SummaryGrid from '../components/Blog/SummaryGrid'
import BlogPreviewCard from '../components/Blog/BlogPreviewCard'
import BreakerPreviewCard from '../components/BreakerPreviewCard'
import { links } from '../styles/links'
import Title from '../components/Title'
import media from '../styles/media'

const StyledTitle = styled(Title)`
  padding: 100px 48px 32px;

  ${media.tablet`
    padding: 45px 24px 27px;
  `}

  ${media.phone`
    padding: 45px 18px 3px;
  `}
`

const TramLines = styled.div`
  width: 100%;
  padding: 0 91px;

  ${media.tablet`
    padding: 0 62px;
  `}

  ${media.phone`
    padding: 0 18px;
  `}
`

const BlogPage = ({ data }) => {
  const gridItems = data.kenticoCloudItemBlogSummary.elements.blog_items
  const pageTitle =
    data.allKenticoCloudItemBlogSummary.nodes[0].elements.title.value
  const {
    seo_metadata_example_to_include_in_any_type__meta_description,
    seo_metadata_example_to_include_in_any_type__meta_title,
    seo_metadata_example_to_include_in_any_type__opengraph_image,
  } = data.kenticoCloudItemBlogSummary.elements
  return (
    <Layout
      location={links.blog}
      metaDesc={
        seo_metadata_example_to_include_in_any_type__meta_description.value
      }
      metaTitle={seo_metadata_example_to_include_in_any_type__meta_title.value}
      metaImg={
        seo_metadata_example_to_include_in_any_type__opengraph_image.value[0]
          .url
      }
    >
      <TramLines>
        <StyledTitle>{pageTitle}</StyledTitle>
        <SummaryGrid>
          {gridItems.map((item, i) => {
            if (item.internal.type === 'KenticoCloudItemBlogDetail') {
              return (
                <LazyLoad>
                  <BlogPreviewCard
                    index={i}
                    key={item.id}
                    to={`/blog/${item.elements.slug.value}`}
                    tags={item.elements.blog_categories.taxonomyTerms}
                    postTitle={item.elements.title.value}
                    tagline={item.elements.subtitle.value}
                    imageUrl={
                      item.elements.preview_image.value.length > 0 &&
                      item.elements.preview_image.value[0].url
                    }
                  />
                </LazyLoad>
              )
            }
            if (item.internal.type === 'KenticoCloudItemBreakerPreviewCard') {
              return (
                <BreakerPreviewCard
                  isBlogItem
                  key={item.id}
                  text={item.elements.text.value}
                  to={item.elements.button_target.value}
                  btnText={item.elements.button_label.value}
                />
              )
            }
          })}
        </SummaryGrid>
      </TramLines>
    </Layout>
  )
}

BlogPage.propTypes = {
  data: PropTypes.object,
}

export default BlogPage

export const blogPageQuery = graphql`
  {
    allKenticoCloudItemBlogSummary {
      nodes {
        elements {
          title {
            value
          }
        }
      }
    }
    kenticoCloudItemBlogSummary(elements: {}) {
      elements {
        seo_metadata_example_to_include_in_any_type__meta_description {
          value
        }
        seo_metadata_example_to_include_in_any_type__meta_title {
          value
        }
        seo_metadata_example_to_include_in_any_type__opengraph_image {
          value {
            url
          }
        }
        blog_items {
          ... on KenticoCloudItemBlogDetail {
            id
            elements {
              title {
                value
              }
              slug {
                value
              }
              subtitle {
                value
              }
              blog_categories {
                taxonomyTerms {
                  name
                }
              }
              preview_image {
                value {
                  url
                }
              }
            }
            internal {
              type
            }
          }
          ... on KenticoCloudItemBreakerPreviewCard {
            elements {
              text {
                value
              }
              button_target {
                value
              }
              button_label {
                value
              }
            }
            internal {
              type
            }
            id
          }
        }
      }
    }
  }
`
