import styled from 'styled-components'
import media from '../../styles/media'
import { red, black, lightGrey } from '../../styles/colors'

export const Section = styled.section`
  font-family: 'Gilroy';
`

export const Container = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 70%;
  background-color: ${lightGrey};
  padding: 80px 3% 80px 30.4%;
  margin-top: 100px;

  ${media.tablet`
    width: 100%;
    padding: 70px 86px 15px;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
  `}

  ${media.phone`
    flex-flow: column;
    margin-top: 36px;
  `}
`

export const StatWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 33%;
  margin-right: 20px;

  ${media.tablet`
    margin-bottom: 55px;
  `}
`

export const StatNum = styled.div`
  font-size: 48px;
  font-weight: 600;
  letter-spacing: -0.48px;
  color: ${red};
`

export const StatLabel = styled.div`
  color: ${black};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2.1px;
  text-transform: uppercase;
`
